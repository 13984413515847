@charset "utf-8";

@keyframes moveY {
  0% {
    transform: translateY(-5px);
  }

  100% {
    transform: translateY(5px);
  }
}

@keyframes enlarge {
  0% {
    background-size: 0%;
    opacity: 0;
  }

  40% {
    background-size: 62%;
    opacity: 1;
  }

  60% {
    background-size: 62%;
    opacity: 1;
  }

  100% {
    background-size: 300%;
    opacity: 0;
  }
}

$items: 0 1 2 3 4 5 6 7 8;
@media screen and (max-width: 960px) {
  .fade-in {
    @each $item in $items {
      .fade-h5-#{$item} {
        opacity: 0.0001;
        transform: translateY(10vw);
      }
    }

    &.in-win {
      @each $item in $items {
        .fade-h5-#{$item} {
          opacity: 1;
          transform: translateY(0);
          transition: transform 1s ease-in-out, opacity 0.6s ease-in-out;
          transition-delay: (0.2s + ($item * 0.2));
        }
      }
    }

    @each $item in $items {
      .fade-down-h5-#{$item} {
        opacity: 0.0001;
        transform: translateY(-10vw);
      }
    }

    &.in-win {
      @each $item in $items {
        .fade-down-h5-#{$item} {
          opacity: 1;
          transform: translateY(0);
          transition: transform 1s ease-in-out, opacity 0.6s ease-in-out;
          transition-delay: (0.2s + ($item * 0.2));
        }
      }
    }

    .fade-h5-img {
      filter: blur(20px);
      opacity: 0;
      transition: opacity ease-in-out 1.4s, filter ease-in-out 1.4s,
        transform ease-in-out 2s;
      transform: translateY(4vw) scale(0.8);
    }

    &.in-win .fade-h5-img {
      filter: blur(0);
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }
}

@media screen and (min-width: 961px) {
  .fade-in {
    @each $item in $items {
      .fade-pc-#{$item} {
        opacity: 0.0001;
        transform: translateY(2vw);
      }
    }

    &.in-win {
      @each $item in $items {
        .fade-pc-#{$item} {
          opacity: 1;
          transform: translateY(0);
          transition: transform 1s ease-in-out, opacity 0.6s ease-in-out;
          transition-delay: (0.2s + ($item * 0.2));
        }
      }
    }

    @each $item in $items {
      .fade-down-pc-#{$item} {
        opacity: 0.0001;
        transform: translateY(-2vw);
      }
    }

    &.in-win {
      @each $item in $items {
        .fade-down-pc-#{$item} {
          opacity: 1;
          transform: translateY(0);
          transition: transform 1s ease-in-out, opacity 0.6s ease-in-out;
          transition-delay: (0.2s + ($item * 0.2));
        }
      }
    }

    .fade-pc-img {
      filter: blur(20px);
      opacity: 0;
      transition: opacity ease-in-out 1.4s, filter ease-in-out 1.4s,
        transform ease-in-out 2s;
      transform: translateY(4vw) scale(0.8);
    }

    &.in-win .fade-pc-img {
      filter: blur(0);
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }
}