@font-face {
  font-family: "HarmonyOS Sans";
  font-weight: 400;
  src: url("https://oss.deriw.com/font/HarmonyOS_Sans_SC_Regular.ttf");
}

@font-face {
  font-family: "HarmonyOS Sans SC";
  font-weight: 400;
  src: url("https://oss.deriw.com/font/HarmonyOS_Sans_SC_Regular.ttf");
}

@font-face {
  font-family: "HarmonyOS Sans Bold";
  font-weight: 700;
  src: url("https://oss.deriw.com/font/HarmonyOS_Sans_SC_Bold.ttf");
}

@font-face {
  font-family: "HarmonyOS Sans Black";
  font-weight: 700;
  src: url("https://oss.deriw.com/font/HarmonyOS_Sans_SC_Black.ttf");
}

@font-face {
  font-family: "HarmonyOS Sans SC Medium";
  font-weight: 700;
  src: url("https://oss.deriw.com/font/HarmonyOS_Sans_SC_Medium.ttf");
}

@font-face {
  font-family: "HarmonyOS Sans SC Thin";
  font-weight: 700;
  src: url("https://oss.deriw.com/font/HarmonyOS_Sans_SC_Thin.ttf");
}