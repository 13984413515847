@import "./variable";

@mixin content() {
  width: 1200px;
  margin: 0 auto
}

@mixin content_mobile() {
  width: calc(100% - 40px);
  margin: 0px auto;
  padding-bottom: 70px;
}

@mixin flex_center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex_between() {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


// 背景颜色
@mixin bg_color() {
  [data-theme="dark"] & {
    background-color: $dark-main;
  }

  [data-theme="light"] & {
    background-color: $light-main;
  }
}

@mixin bg_color2() {
  [data-theme="dark"] & {
    background-color: $light-main;
  }

  [data-theme="light"] & {
    background-color: $dark-main;
  }
}

@mixin bg_color4() {
  [data-theme="dark"] & {
    background-color: #000;
  }

  [data-theme="light"] & {
    background-color: #fff;
  }
}

@mixin bg_color3() {
  [data-theme="dark"] & {
    background-color: #16141A;
  }

  [data-theme="light"] & {
    background-color: #F7F7F7;
  }
}

@mixin bg_color5() {
  [data-theme="dark"] & {
    background-color: #03010D;
  }

  [data-theme="light"] & {
    background-color: #F7F7F7;
  }
}

// 挑战赛详情背景颜色
@mixin challengeDetail_bg_color() {
  [data-theme="dark"] & {
    background-color: $dark-main;
  }

  [data-theme="light"] & {
    background-color: #FAFAFC;
  }
}

// 挑战赛详情中间内容背景颜色
@mixin challengeDetail_data_bg_color() {
  [data-theme="dark"] & {
    background-color: #171520;
  }

  [data-theme="light"] & {
    background-color: #fff;
  }
}

// 字体颜色
@mixin font_color() {
  [data-theme="dark"] & {
    color: $light-main;
  }

  [data-theme="light"] & {
    color: $dark-main;
  }
}

// 字体颜色
@mixin font_color_imp_dis() {
  [data-theme="dark"] & {
    color: rgba($light-main, 0.25);
  }

  [data-theme="light"] & {
    color: rgba($dark-main, 0.25);
  }
}

// 字体颜色
@mixin font_color_imp() {
  [data-theme="dark"] & {
    color: $light-main !important;
  }

  [data-theme="light"] & {
    color: $dark-main !important;
  }
}

// 字体颜色
@mixin font_color2() {
  [data-theme="dark"] & {
    color: $dark-main;
  }

  [data-theme="light"] & {
    color: $light-main;
  }
}

@mixin walletConnect_bg {
  [data-theme="dark"] & {
    background-color: var(--fill-component, rgba(255, 255, 255, 0.08));
  }

  [data-theme="light"] & {
    background-color: var(--fill-component, rgba(26, 26, 26, 0.05));
  }
}

@mixin createChallenge_bg {
  [data-theme="dark"] & {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0) 68.36%);
  }

  [data-theme="light"] & {
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 68.36%);

  }
}

@mixin createChallenge_form_input_bg {
  [data-theme="dark"] & {
    background-color: var(--fill-component, rgba(255, 255, 255, 0.08));
  }

  [data-theme="light"] & {
    background-color: var(--fill-component, rgba(26, 26, 26, 0.05));
  }
}

@mixin createChallenge_form_input_bg_imp {
  [data-theme="dark"] & {
    background-color: var(--fill-component, rgba(255, 255, 255, 0.08)) !important;
  }

  [data-theme="light"] & {
    background-color: var(--fill-component, rgba(26, 26, 26, 0.05)) !important;
  }
}

@mixin createChallenge_form_input_placeholder {
  [data-theme="dark"] & {
    color: var(--text-tertiary, #626365);
  }

  [data-theme="light"] & {
    color: var(--text-tertiary, #A1A1A6);
  }
}

@mixin rangePicker-svg-color {
  [data-theme="dark"] & {
    color: var(--text-tertiary, #A5A6AC);

  }

  [data-theme="light"] & {
    color: var(--text-tertiary, #646465);
  }
}

@mixin shu-bg {
  [data-theme="dark"] & {
    background-color: var(--Border-divider, rgba(255, 255, 255, 0.10));
  }

  [data-theme="light"] & {
    background-color: var(--Border-divider, rgba(26, 26, 26, 0.10));
  }
}

@mixin hd-bg {
  [data-theme="dark"] & {
    background-color: var(--Border-divider, rgba(255, 255, 255, 0.10));
  }

  [data-theme="light"] & {

    background-color: rgba(3, 1, 13, 0.05);
  }
}

@mixin table_tr_border {
  [data-theme="dark"] & {
    border-bottom: 1px solid var(--Border-divider, rgba(255, 255, 255, 0.10));
  }

  [data-theme="light"] & {
    border-bottom: 1px solid var(--Border-divider, rgba(26, 26, 26, 0.10));
  }
}


// 挑战赛详情按钮禁用
@mixin detail_dis_btn_bg() {
  [data-theme="dark"] & {
    background-color: rgba(255, 255, 255, 0.08);
  }

  [data-theme="light"] & {
    background-color: rgba(3, 1, 13, 0.05);
  }
}

@mixin transaction_data_color {
  [data-theme="dark"] & {
    color: #62636F;
  }

  [data-theme="light"] & {
    color: #A9AAB8;

  }
}

// 
@mixin transaction_data_color2 {
  [data-theme="dark"] & {
    color: #62636F;
  }

  [data-theme="light"] & {
    color: #A9AAB8;

  }
}

// 交易页面 次级文字颜色
@mixin transaction_secondary_font_color {
  [data-theme="dark"] & {
    color: rgba($color: #9597A8, $alpha: 0.8) !important;
  }

  [data-theme="light"] & {
    color: rgba($color: #646465, $alpha: 0.6) !important;

  }
}

// 交易页面 虚线border颜色
@mixin mark_price_border {
  [data-theme="dark"] & {
    border-color: rgba($color: #9597A8, $alpha: 0.8);
  }

  [data-theme="light"] & {
    border-color: rgba($color: #646465, $alpha: 0.6);

  }
}

// 交易页面 仓位数据背景颜色
@mixin transaction_position_bg {
  [data-theme="dark"] & {
    background-color: rgba($color: #1F1D25, $alpha: 0.8)
  }

  [data-theme="light"] & {
    background-color: rgba($color: #fff, $alpha: 1)
  }
}

// 交易页面 仓位数据背景颜色
@mixin transaction_position_table_border {
  [data-theme="dark"] & {
    border-bottom: 1px solid rgba(149, 151, 168, 0.10);
  }

  [data-theme="light"] & {
    border-bottom: 1px solid rgba(149, 151, 168, 0.10);
  }
}

// 交易页面 次级文字颜色
@mixin transaction_secondary_font_color2 {
  [data-theme="dark"] & {
    color: #9597A8;
  }

  [data-theme="light"] & {
    color: #646465;
  }
}



@mixin transaction_slider_bg {
  [data-theme="dark"] & {
    background-color: rgba($color: #9597A8, $alpha: 0.6)
  }

  [data-theme="light"] & {
    background-color: rgba($color: #9597A8, $alpha: 0.3)
  }
}

// 交易页面 
@mixin transaction_input_bg {
  [data-theme="dark"] & {
    background-color: rgba($color: #121015, $alpha: 0.8)
  }

  [data-theme="light"] & {
    background-color: rgba($color: #EDEEF0, $alpha: 1)
  }
}

@mixin home_btn_border {
  [data-theme="dark"] & {
    border: 2px solid var(--Text-static-white, #FFF);
  }

  [data-theme="light"] & {
    border: 2px solid var(--Text-static-white, #07050F);
  }
}

@mixin home_btn_border2 {
  [data-theme="dark"] & {
    border: 1px solid var(--Text-static-white, #FFF);
  }

  [data-theme="light"] & {
    border: 1px solid var(--Text-static-white, #07050F);
  }
}

@mixin home_bg1 {
  [data-theme="dark"] & {
    background-image: url(../assets/images/home_bg1_dark.jpg);
  }

  [data-theme="light"] & {
    background-image: url(../assets/images/home_bg1_light.jpg);
  }
}

@mixin home_bg2 {
  [data-theme="dark"] & {
    background-image: url(../assets/images/home_bg2_dark.png);
  }

  [data-theme="light"] & {
    background-image: url(../assets/images/home_bg2_light.png);
  }
}

@mixin home_deriw_bgc {
  [data-theme="dark"] & {
    background-color: #0F0D1A;
  }

  [data-theme="light"] & {
    background-color: #FAFAFC;
  }
}

@mixin modal_bg_box_shadow {
  [data-theme="dark"] & {
    box-shadow: unset;
  }

  [data-theme="light"] & {
    box-shadow: 0px 4px 16px 0px rgba(26, 26, 26, 0.10);
  }
}


@mixin switch_theme_bg {
  [data-theme="dark"] & {
    background-color: var(--Button-secondary-default, rgba(255, 255, 255, 0.08));
  }

  [data-theme="light"] & {
    background-color: var(--Button-secondary-default, rgba(25, 25, 26, 0.05));
  }
}


@mixin earn_content_bg {
  [data-theme="dark"] & {
    background-color: #171520;
  }

  [data-theme="light"] & {
    background-color: #F7F7F7;
  }
}

@mixin earn_font_color {
  [data-theme="dark"] & {
    color: #fff;
  }

  [data-theme="light"] & {
    color: #221C2D;
  }
}



@mixin earn_font_color2 {
  [data-theme="dark"] & {
    color: #686A76;
  }

  [data-theme="light"] & {
    color: #686A76;
  }
}


// 交易页面 次级文字颜色
@mixin earn_font_color3 {
  [data-theme="dark"] & {
    color: #646465;
  }

  [data-theme="light"] & {
    color: #9597A8;
  }
}


@mixin ecology_bg {
  [data-theme="dark"] & {
    background-color: $dark-main;
  }

  [data-theme="light"] & {
    background-color: #fff;
  }
}

@mixin ecology_bg2 {
  [data-theme="dark"] & {
    background-color: #fff;
  }

  [data-theme="light"] & {
    background-color: #221C2D;
  }
}



// 交易页面 次级文字颜色
@mixin ecology_btn_font {
  [data-theme="dark"] & {
    color: #221C2D;
  }

  [data-theme="light"] & {
    color: #FFF;
  }
}


@mixin challenge_bg3 {
  [data-theme="dark"] & {
    background-color: rgba(23, 21, 32, 0.70);
  }

  [data-theme="light"] & {
    background-color: var(--Fill-bg-bg-secondary-white, #FFF);
    box-shadow: 0px 4px 24px 0px rgba(24, 21, 38, 0.05);
  }
}



@mixin filter_bg {
  [data-theme="dark"] & {
    background-color: #2D2C34;
  }

  [data-theme="light"] & {
    background-color: #b0b0b0;
  }
}

@mixin ant-picker_bg {
  [data-theme="dark"] & {
    background-color: #34323B;
  }

  [data-theme="light"] & {
    background-color: #FFF;
  }
}



@mixin home_start_btn_bgimg {
  [data-theme="dark"] & {
    background-image: url(../assets/images/btn_starttrading.png);
  }

  [data-theme="light"] & {
    background-image: url(../assets/images/btn_starttrading_light.png);
  }
}

@mixin home_start_btn_bgimg_hover {
  [data-theme="dark"] & {
    background-image: url(../assets/images/btn_starttrading_light.png);
  }

  [data-theme="light"] & {
    background-image: url(../assets/images/btn_starttrading.png);
  }
}

@mixin last_btnHover {

  [data-theme="dark"] & {
    color: #000;
    background-color: #fff;
  }

  [data-theme="light"] & {
    color: #fff;
    background-color: #000;
  }
}

@mixin recommend_bg {

  [data-theme="dark"] & {
    background-color: rgba(19, 17, 22, 1);

  }

  [data-theme="light"] & {
    background-color: rgba(237, 238, 240, 1);
  }
}

@mixin deriwadv_bg {

  [data-theme="dark"] & {
    background-color: #1B191F;

  }

  [data-theme="light"] & {
    background-color: #F5F5F7;
  }
}

@mixin deriwadv_span_color {

  [data-theme="dark"] & {
    color: #7A7A7A;

  }

  [data-theme="light"] & {
    color: $dark-main;
  }
}

@mixin crosschain_color {

  [data-theme="dark"] & {
    color: #fff;

  }

  [data-theme="light"] & {
    color: #19191A;
  }
}


@mixin crosschain_bg {

  [data-theme="dark"] & {
    background-color: #242429;
    box-shadow: 0px 4px 32px 0px #00000014;

  }

  [data-theme="light"] & {
    background-color: #fff;
    box-shadow: 0px 4px 32px 0px #00000014;
  }
}

@mixin crosschain_selectnetwork_bg {

  [data-theme="dark"] & {
    background-color: #292732;
    border: 1px solid #FFFFFF1A;

  }

  [data-theme="light"] & {
    border: 1px solid #1A1A1A1A;
    background-color: #fff;
    box-shadow: 0px 2px 16px 0px #03010D1A;
  }
}

@mixin crosschain_toinput_bg {

  [data-theme="dark"] & {
    background-color: #131116;


  }

  [data-theme="light"] & {
    background-color: #19191A0D;

  }
}

@mixin crosschain_btn_dis {

  [data-theme="dark"] & {
    background-color: #FFFFFF14;
    color: #626365;
  }

  [data-theme="light"] & {
    background-color: #19191A0D;
    color: #A1A1A6;

  }
}

@mixin crosschain_text_dis {

  [data-theme="dark"] & {
    color: #626365;
  }

  [data-theme="light"] & {
    color: #A1A1A6;

  }
}


@mixin crosschain_selectcoin_border {

  [data-theme="dark"] & {
    border-left: 1px solid #FFFFFF14;

  }

  [data-theme="light"] & {
    border-left: 1px solid #19191A0D;

  }
}

@mixin crosschain_selectcoin_input_border {

  [data-theme="dark"] & {
    border: 1px solid #FFFFFF1F;

  }

  [data-theme="light"] & {
    border: 1px solid #19191A33
  }
}


@mixin crosschain_setting_bg {

  [data-theme="dark"] & {
    background-color: #292732;

  }

  [data-theme="light"] & {
    background-color: #fff;
  }
}


@mixin crosschain_settings_tab_border {

  [data-theme="dark"] & {
    border-bottom: 1px solid #FFFFFF1A;
  }

  [data-theme="light"] & {
    border-bottom: 1px solid #1A1A1A1A;
  }
}

@mixin crosschain_settings_tab_color {

  [data-theme="dark"] & {
    color: #626365;
  }

  [data-theme="light"] & {
    color: #A1A1A6;
  }
}


@mixin home_zero_bg {

  [data-theme="dark"] & {
    background-color: #141318;
  }

  [data-theme="light"] & {
    background-color: #F5F5F7;
  }
}


@mixin zero_color2 {

  [data-theme="dark"] & {
    color: #FFFFFF99;
  }

  [data-theme="light"] & {
    color: #03010DB2;

  }
}


@mixin select_item_bg {

  [data-theme="dark"] & {
    background-color: #26252D;
  }

  [data-theme="light"] & {
    background-color: #fff;
    box-shadow: 0px 4px 16px 0px #1A1A1A1A;
  }
}



@mixin search_coin_bg {
  [data-theme="dark"] & {
    background-color: #1F1D25;
    border: 1px solid var(--line-border, #FFFFFF1A);
    box-shadow: 0px 4px 16px 0px #1A1A1A1A;;
  }
  [data-theme="light"] & {
    background-color: #fff;
    border: 1px solid var(--line-border, #1A1A1A1A);
    box-shadow: 0px 4px 16px 0px #1A1A1A1A;
  }
}
@mixin search_coin_input_bg {
  [data-theme="dark"] & {
    background-color: #131116;
    // border: 1px solid var(--text-icon-01, #FFFFFF)
  }
  [data-theme="light"] & {
    background-color: #EDEEF0;
    // border: 1px solid var(--text-icon-01, #221C2D)
  }
}
@mixin search_coin_input_outline {
  [data-theme="dark"] & {
    outline: 1px solid #FFFFFF;
    // border: 1px solid var(--text-icon-01, #FFFFFF)
  }
  [data-theme="light"] & {
    outline: 1px solid #221C2D;
    // border: 1px solid var(--text-icon-01, #221C2D)
  }
}

@mixin option_hover_bg {

  [data-theme="dark"] & {
    background-color: var(--Button-secondary-default, rgba(255, 255, 255, 0.08));
  }

  [data-theme="light"] & {
    background-color: #03010D0D;
  }
}

@mixin pagination_color {
  [data-theme="dark"] & {
    color: #A5A6AC;

  }

  [data-theme="light"] & {
    color: #646465;
  }
}

@mixin pagination_border_color {
  [data-theme="dark"] & {
    border: 1px solid #FFFFFF1A;

  }

  [data-theme="light"] & {
    border: 1px solid #1A1A1A1A;
  }
}


@mixin flow_chart_item_border {
  [data-theme="dark"] & {

    border-left: 1px dashed #FFFFFF66;

  }

  [data-theme="light"] & {

    border-left: 1px dashed #03010D66;
  }
}


@mixin slider_bg {

  [data-theme="dark"] & {
    background-color: #211E27;

  }

  [data-theme="light"] & {
    background-color: #FDFDFD;
  }
}

@mixin switch_bg {

  [data-theme="dark"] & {
    background-color: #DBDCDE;

  }

  [data-theme="light"] & {
    background-color: #9597A826;

  }
}


@mixin switch_bg2 {

  [data-theme="dark"] & {
    background-color: #9597A826;

  }

  [data-theme="light"] & {
    background-color: #00000026;


  }
}


@mixin cmodal2_bg {

  [data-theme="dark"] & {
    box-shadow: 0px 4px 16px 0px #1A1A1A1A;
    background-color: #1F1D25;
  }

  [data-theme="light"] & {
    box-shadow: 0px 4px 16px 0px #1A1A1A1A;
    background-color: #fff;
  }
}

@mixin cmodal3_bg {

  [data-theme="dark"] & {
    // box-shadow: 0px 4px 16px 0px #1A1A1A1A;
    background-color: #1B191F;
  }

  [data-theme="light"] & {
    // box-shadow: 0px 4px 16px 0px #1A1A1A1A;
    background-color: #fff;
  }
}

@mixin cmodal4_bg {

  [data-theme="dark"] & {
    background-color: #2F2E37;
    box-shadow: 0px 4px 16px 0px #1A1A1A1A;
  }

  [data-theme="light"] & {
    box-shadow: 0px 4px 16px 0px #1A1A1A1A;
    background-color: #fff;
  }
}


// 背景颜色
@mixin cmodal2_input_bg() {
  [data-theme="dark"] & {
    background-color: $dark-main;
  }

  [data-theme="light"] & {
    background-color: #03010D0D;

  }
}


// home_text3
@mixin home_text3_color() {
  [data-theme="dark"] & {
    color: #FFFFFFB2;
  }

  [data-theme="light"] & {
    color: #03010DB2;
  }
}

// home_text4
@mixin home_text4_color() {
  [data-theme="dark"] & {
    color: #FFFFFF99;

  }

  [data-theme="light"] & {
    color: #03010DB2;

  }
}

// 
@mixin flow_chart_dian_bg() {
  [data-theme="dark"] & {
    background-color: #FFFFFF33;
  }

  [data-theme="light"] & {
    background-color: #03010D33;
  }
}

@mixin flow_chart_line1_bg() {
  [data-theme="dark"] & {
    background-color: #D9D9D9;
  }

  [data-theme="light"] & {
    background-color: #03010D;


  }
}

@mixin flow_chart_line_bg() {
  [data-theme="dark"] & {
    background-color: rgba(#fff, 0.2);
  }

  [data-theme="light"] & {
    background-color: rgba(#03010D, 0.2);


  }
}


@mixin deriw_adv_text_span {

  [data-theme="dark"] & {
    color: #7A7A7A;

  }

  [data-theme="light"] & {
    color: $dark-main;
  }
}

@mixin setting_hd_bg {

  [data-theme="dark"] & {
    background-color: #FFFFFF0D;

  }

  [data-theme="light"] & {
    background-color: #19191A0D;

  }
}

@mixin transaction_btn_bg {
  [data-theme="dark"] & {
    background-color: #9597A81A;
  }

  [data-theme="light"] & {
    background-color: #9597A81A;
  }
}

@mixin position_drop_bg {
  [data-theme="dark"] & {
    background-color: #292730;
  }

  [data-theme="light"] & {
    background-color: #FFFFFF;
    box-shadow: 0px 4px 16px 0px rgba(26, 26, 26, 0.10);
  }
}

@mixin kline_mask_bg {

  [data-theme="dark"] & {
    background-color: rgba($color: #16141A, $alpha: 0.8);

  }

  [data-theme="light"] & {
    background-color: rgba($color: #FFFFFF, $alpha: 0.8);


  }
}


@mixin earn_status_border {

  [data-theme="dark"] & {
    border-top: 1px solid #FFFFFF1A;
  }

  [data-theme="light"] & {
    border-top: 1px solid #1A1A1A1A;
  }
}

@mixin status_p_bg {

  [data-theme="dark"] & {
    background-color: #03010D1A;
  }

  [data-theme="light"] & {
    background-color: #03010D1A;

  }
}


@mixin bg_color3 {

  [data-theme="dark"] & {
    background-color: #16141A;
  }

  [data-theme="light"] & {
    background-color: #F7F7F7;

  }
}

@mixin recommend_item_bg {

  [data-theme="dark"] & {
    background-color: #1D1B23;
  }

  [data-theme="light"] & {
    background-color: #FFF;
  }
}

@mixin recommend_input_bg {

  [data-theme="dark"] & {
    background-color: #131116;
  }

  [data-theme="light"] & {
    background-color: #F7F7F7;
  }
}

@mixin recommend_input_bg2 {

  [data-theme="dark"] & {
    background-color: #131116;
  }

  [data-theme="light"] & {
    background-color: #EDEEF0;
  }
}

@mixin recommend_input_bg3 {

  [data-theme="dark"] & {
    background-color: #0C0B0E;
  }

  [data-theme="light"] & {
    background-color: #EDEEF0;
  }
}


@mixin recommend_btn_bg {

  [data-theme="dark"] & {
    background-color: #232128;
  }

  [data-theme="light"] & {
    background-color: #F7F7F7;
  }
}


@mixin recommend_text_color3 {

  [data-theme="dark"] & {
    color: #62636F;
  }

  [data-theme="light"] & {
    color: #221C2D;
  }
}


@mixin recommend_share_text_color {
  [data-theme="dark"] & {
    color: rgba($color: #FFF, $alpha: 0.7);
  }

  [data-theme="light"] & {
    color: rgba($color: #221C2D, $alpha: 0.7);
  }
}

@mixin node_title {
  [data-theme="dark"] & {
    color: #F4F5F6;
  }

  [data-theme="light"] & {
    color: #03010D;

  }
}


@mixin wallet_act_bg {

  [data-theme="dark"] & {
    background-color: #FFFFFF14 !important;
  }

  [data-theme="light"] & {
    background-color: #1A1A1A0D !important;

  }
}

@mixin walletconnect_active_bg {

  [data-theme="dark"] & {
    background-color: #673CD8 !important;
  }

  [data-theme="light"] & {
    background-color: #673CD8 !important;

  }
}

@mixin modal_btn_bg2 {

  [data-theme="dark"] & {
    background-color: #232128;
  }

  [data-theme="light"] & {
    background-color: #f3f3f4;

  }
}

@mixin creatr_bg {

  [data-theme="dark"] & {
    background: linear-gradient(180deg, rgba(151, 142, 255, 0.1) 0%, rgba(18, 18, 18, 0) 71.38%);

  }

  [data-theme="light"] & {
    background: linear-gradient(180deg, rgba(151, 142, 255, 0.1) 0%, rgba(18, 18, 18, 0) 71.38%);


  }
}

@mixin echarts-tooltip {
  [data-theme="dark"] & {
    background-color: #393840 !important;
    box-shadow: 0px 2px 6px 0px #1A1A1A1A !important;
  }

  [data-theme="light"] & {
    background-color: #F9F9F9;
    box-shadow: 0px 2px 16px 0px #18152629 !important;
  }
}

@mixin echarts-tooltip-color {

  [data-theme="dark"] & {
    color: #fff !important;
  }

  [data-theme="light"] & {
    color: #03010D !important;
  }
}

@mixin cha_tab_item_act {
  [data-theme="dark"] & {
    color: #03010D;
    background-color: #FFF !important;
  }

  [data-theme="light"] & {
    background-color: #03010D;
    color: #fff !important;
  }
}

@mixin cha_tab_item_act2 {
  [data-theme="dark"] & {
    color: #fff;
  }

  [data-theme="light"] & {
    color: #03010D;
  }
}

@mixin ant-picker_border() {
  [data-theme="dark"] & {
    border-color: $light-main !important;
  }

  [data-theme="light"] & {
    border-color: $dark-main !important;
  }
}

@mixin nav_border {

  [data-theme="dark"] & {
    border: 1px solid #2A2832;

  }

  [data-theme="light"] & {
    border: 1px solid #19191A0D;

  }
}

@mixin trading_compentition_bg {
  [data-theme="dark"] & {
    background-image: url(../assets/images/trading_compentition_bg.png);
  }

  [data-theme="light"] & {
    background-image: url(../assets/images/trading_compentition_bg.png);
  }
}

@mixin trading_compentition_bg_m {
  [data-theme="dark"] & {
    background-image: url(../assets/images/trading_compentition_dark_m.png);
  }

  [data-theme="light"] & {
    background-image: url(../assets/images/trading_compentition_light_m.png);
  }
}

@mixin trading_compentition_rank_bg {
  [data-theme="dark"] & {
    background-color: #110F1A;
  }

  [data-theme="light"] & {
    background-color: #F7F7F7;
  }
}

@mixin trading_compentition_rank_tab_bg {
  [data-theme="dark"] & {
    background-color: #000000;
    border: 1px solid #9597A84D;
  }

  [data-theme="light"] & {
    background-color: #FFF;
    border: 1px solid #9597A84D;
  }
}


@mixin dashboard_title {
  [data-theme="dark"] & {
    color: #F4F5F6;
  }

  [data-theme="light"] & {
    color: #221C2D;
  }
}

@mixin ant-tooltip_bg() {
  [data-theme="dark"] & {
    background-color: #292732 !important;
    border: 1px solid #FFFFFF1A;
  }

  [data-theme="light"] & {
    background-color: #F7F7F7 !important;
    border: 1px solid #1A1A1A1A;
  }
}

@mixin ant-tooltip_arrow_bg () {

  [data-theme="dark"] & {
    --antd-arrow-background-color: linear-gradient(to right bottom, #313039, #313039);
  }

  [data-theme="light"] & {
    --antd-arrow-background-color: linear-gradient(to right bottom, #FFF, #FFF);
  }
}

@mixin trading_compentition_event_border () {
  [data-theme="dark"] & {
    border: 1px solid #FFFFFF33;
  }

  [data-theme="light"] & {
    border: 1px solid #221C2D;
  }
}



@mixin trading_competition_modal_m_bg() {
  [data-theme="dark"] & {
    background-color: #000;
  }

  [data-theme="light"] & {
    background-color: #FFF;
  }
}

@mixin trading_competition_modal_m_p_bg() {
  [data-theme="dark"] & {
    background-color: #1A1A1A;
  }

  [data-theme="light"] & {
    background-color: #F3F3F4;
  }
}

@mixin ant-pagination-item-link_dis_color() {
  [data-theme="dark"] & {
    color: rgba(#fff, 0.32);
  }

  [data-theme="light"] & {
    color: rgba(#221C2D, 0.32);
  }
}

@mixin bounty_hunter_bg {
  [data-theme="dark"] & {
    background-image: url(../assets/images/bounty_hunter_dark.png);
  }

  [data-theme="light"] & {
    background-image: url(../assets/images/bounty_hunter_light.png);
  }
}

@mixin bounty_hunter_bg_m {
  [data-theme="dark"] & {
    background-image: url(../assets/images/bounty_hunter_dark_m.png);
  }

  [data-theme="light"] & {
    background-image: url(../assets/images/bounty_hunter_light_m.png);
  }
}

@mixin bug_competition_bg {
  [data-theme="dark"] & {
    background-image: url(../assets/images/bug_competition_bg.png);
  }

  [data-theme="light"] & {
    background-image: url(../assets/images/bug_competition_bg.png);
  }
}

@mixin bug_competition_bg_m {
  [data-theme="dark"] & {
    background-image: url(../assets/images/bug_competition_dark_m.png);
  }

  [data-theme="light"] & {
    background-image: url(../assets/images/bug_competition_light_m.png);
  }
}

@mixin bounty_hunter_task_content_bg {
  [data-theme="dark"] & {
    background-color: #24222C;
  }

  [data-theme="light"] & {
    background-color: #FFF;
  }
}

@mixin bounty_hunter_task_table_tr_border {
  [data-theme="dark"] & {
    border-bottom: 1px solid #232128;
  }

  [data-theme="light"] & {
    border-bottom: 1px solid #A9AAB8;
  }
}

@mixin bounty_hunter_remarks_border {
  [data-theme="dark"] & {

    border: 1px solid #232128;
  }

  [data-theme="light"] & {
    border: 1px solid #A9AAB8;
  }
}

@mixin submit_bug_input {
  [data-theme="dark"] & {

    color: #62636F;
  }

  [data-theme="light"] & {
    color: #A9AAB8;
  }
}

@mixin browser_notification_border {
  [data-theme="dark"] & {
    border-color: #FFFFFF1A;

  }

  [data-theme="light"] & {
    border-color: #0000001A;
  }
}

@mixin confirm_tx_line4_color {
  [data-theme="dark"] & {
    color: #62636F;

  }

  [data-theme="light"] & {
    color: #A9AAB8;
  }
}
@mixin generate_bg_color {
  [data-theme="dark"] & {
    background-color: #292732;

  }

  [data-theme="light"] & {
    background-color: #F7F7F7;
  }
}
@mixin logo_s_bg {
  [data-theme="dark"] & {
    background-color: #2E2C36;

  }

  [data-theme="light"] & {
    background-color: #E5E5E5;
  }
}

@mixin logo_s_bg2 {
  [data-theme="dark"] & {
    background-color: #FFFFFF1A;

  }

  [data-theme="light"] & {
    background-color: #1A1A1A1A;
  }
}

@mixin home_referrals_info_item_bg {
  [data-theme="dark"] & {
    background-color: rgba($color: #1F1D25, $alpha: 1)
  }

  [data-theme="light"] & {
    background-color: rgba($color: #fff, $alpha: 1)
  }
}
@mixin deriw_mid_color {
  [data-theme="dark"] & {
    color: #FFFFFF99;
  }

  [data-theme="light"] & {
    color: #00000099;
  }
}


@mixin home_dex_adv_bg {

  [data-theme="dark"] & {
    background-color: #292732;

  }

  [data-theme="light"] & {
    background-color: #9597A81A;
  }
}

@mixin activity_line_bg {
  [data-theme="dark"] & {
    background-color: #62636F;
  }

  [data-theme="light"] & {
    background-color: #A9AAB8;

  }
}


@mixin progress_bg {
  [data-theme="dark"] & {
    background-color: #FFFFFF1A;

  }

  [data-theme="light"] & {
    background-color: #0000001A;
  }
}