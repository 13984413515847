@import "../../styles/mixin.scss";
@import "../../styles/animate.scss";
@import "../../styles/fade-animate.scss";


@media (min-width: 961px) {
  .secondary_metamask_icon {
    width: 20px;
    margin-right: 8px;
  }
  .mnemonic_modal {
    padding: 12px 0px 0;

    .mnemonic_line {
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.41px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      @include submit_bug_input();
    }

    .mnemonic_content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      .mnemonic_item {
        width: calc((100% - 16px) / 3);
        height: 48px;
        font-family: HarmonyOS Sans Bold;
        font-size: 14px;
        font-weight: 700;
        line-height: 16.41px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        @include submit_bug_input();
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #9597A81A;
        border-radius: 12px;
        margin-top: 8px;

        span {
          @include earn_font_color();
          padding-left: 8px;
        }
      }

      .mnemonic_item_input_box {
        width: calc((100% - 16px) / 3);
        height: 48px;
        font-family: HarmonyOS Sans Bold;
        font-size: 14px;
        font-weight: 700;
        line-height: 16.41px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        @include submit_bug_input();
        display: flex;
        align-items: center;
        justify-content: center;
        @include recommend_input_bg2();
        border-radius: 12px;
        margin-top: 8px;
        position: relative;

        span {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 12px;
        }
        .mnemonic_item_input {
          width: 100%;
          height: 100%;
          background-color: transparent;
          text-align: center;
          padding: 0 20px 0 35px;
          @include earn_font_color();
          border: none;
          outline: none;
        }
      }
    }

    .act_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;

      img {
        cursor: pointer;
      }

      .copy, .eyes {
        width: 24px;
      }

      .copy_box, .eyes_box {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #9597A81A;
        border-radius: 12px;
        width: 48px;
        height: 48px;
      }
    }

    .confirm_tx_btn_box {
      display: flex;
      align-items: center;
      justify-content: space-between;

    }

    .confirm_tx_btn {
      margin-top: 8px;
      border-radius: var(--Radius-Full, 100px);
      background-color: #673CD8;
      width: 100%;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 12px;
      color: var(--Text-static-white, #FFF);
      text-align: center;
      font-family: "HarmonyOS Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
    }
    .confirm_tx_btn2 {
      margin-top: 24px;
    }

    .confirm_tx_btn3 {
      width: calc((100% - 12px) / 2);
    }
    .confirm_tx_btn_bg2 {
      @include earn_font_color();
      background-color: #9597A81A;
    }

  }
}

@media (max-width: 960px) {

  .secondary_metamask_icon {
    width: 20px;
    margin-right: 8px;
  }
  .mnemonic_modal {
    padding: 12px 0px 0;

    .mnemonic_line {
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.41px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      @include submit_bug_input();
    }

    .mnemonic_content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      .mnemonic_item {
        width: calc((100% - 16px) / 3);
        height: 48px;
        font-family: HarmonyOS Sans Bold;
        font-size: 14px;
        font-weight: 700;
        line-height: 16.41px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        @include submit_bug_input();
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #9597A81A;
        border-radius: 12px;
        margin-top: 8px;

        span {
          @include earn_font_color();
          padding-left: 8px;
        }
      }

      .mnemonic_item_input_box {
        width: calc((100% - 16px) / 3);
        height: 48px;
        font-family: HarmonyOS Sans Bold;
        font-size: 14px;
        font-weight: 700;
        line-height: 16.41px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        @include submit_bug_input();
        display: flex;
        align-items: center;
        justify-content: center;
        @include recommend_input_bg2();
        border-radius: 12px;
        margin-top: 8px;
        position: relative;

        span {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 12px;
        }
        .mnemonic_item_input {
          width: 100%;
          height: 100%;
          background-color: transparent;
          text-align: center;
          padding: 0 20px 0 35px;
          @include earn_font_color();
          border: none;
          outline: none;
        }
      }
    }

    .act_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;

      img {
        cursor: pointer;
      }

      .copy, .eyes {
        width: 24px;
      }

      .copy_box, .eyes_box {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #9597A81A;
        border-radius: 12px;
        width: 48px;
        height: 48px;
      }
    }

    .confirm_tx_btn_box {
      display: flex;
      align-items: center;
      justify-content: space-between;

    }

    .confirm_tx_btn {
      margin-top: 8px;
      border-radius: var(--Radius-Full, 100px);
      background-color: #673CD8;
      width: 100%;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 12px;
      color: var(--Text-static-white, #FFF);
      text-align: center;
      font-family: "HarmonyOS Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
    }
    .confirm_tx_btn2 {
      margin-top: 24px;
    }

    .confirm_tx_btn3 {
      width: calc((100% - 12px) / 2);
    }
    .confirm_tx_btn_bg2 {
      @include earn_font_color();
      background-color: #9597A81A;
    }

  }
}