@import "../../styles/mixin.scss";
@import "../../styles/animate.scss";
@import "../../styles/fade-animate.scss";

@media (min-width: 961px) {
    .home_mint_button {
        min-width: 96px;
        height: 40px;
        padding-left: 16px;
        padding-right: 16px;
        display: flex;
        align-items: center;

        border-radius: var(--Radius-Full, 100px);
        @include home_btn_border2();
        @include font_color();
        font-family: HarmonyOS Sans Bold;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        cursor: pointer;
        text-align: center;
        /* 100% */

        img {
            width: 22px;
            margin-right: 2px;
        }
    }

    .home_mint_button:hover {
        transform: scale(1.05);
    }
}

@media (max-width: 960px) {

    .home_mint_button {
        min-width: 50px;
        height: 30px;
        padding-left: 5px;
        padding-right: 5px;
        display: flex;
        align-items: center;

        border-radius: var(--Radius-Full, 100px);
        @include home_btn_border2();
        @include font_color();
        color: var(--Text-static-white, #FFF);
        font-family: HarmonyOS Sans Bold;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 14px;
        cursor: pointer;
        text-align: center;
        margin-right: 8px;
        /* 100% */

        img {
            width: 16px;
            margin-right: 2px;
        }
    }
}