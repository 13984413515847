@import "../../styles/mixin.scss";

.setting-m-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  @include bg_color();
  padding-bottom: 20px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title {
    @include crosschain_color();
    height: 48px;
    font-family: HarmonyOS Sans SC;
    font-size: 18px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0px;
    text-align: center;
    position: relative;

    img {
      position: absolute;
      width: 24px;
      left: 24px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .setting-m-content {
    padding: 12px 24px 24px;


    .lable {
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      @include pagination_color();
      padding-top: 28px;
    }

    .hd_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 8px;
      position: relative;

      .hd_item {
        @include setting_hd_bg();
        border-radius: var(--Radius-M-8px, 8px);
        cursor: pointer;
        width: calc((100% - 36px) / 4);
        height: 48px;
        @include pagination_color();
        text-align: center;
        font-family: "HarmonyOS Sans Bold";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .hd_item_act {
        @include font_color();
        border: 2px solid #673CD8;
      }

      input {
        width: 100%;
        height: 48px;
        border-radius: 8px;

        @include setting_hd_bg();
        border: none;
        outline: none;
        padding: 0 80px 0 16px;

        @include font_color();
        font-family: "HarmonyOS Sans SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }

      .dot {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;
        @include font_color();
        font-family: "HarmonyOS Sans SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  .btn {
    margin: 0px auto;
    border-radius: var(--Radius-Full, 100px);
    background-color: #673CD8;
    width: calc(100% - 24px);
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    color: var(--Text-static-white, #FFF);
    text-align: center;
    font-family: "HarmonyOS Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
  }
}