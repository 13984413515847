@import "../../styles/animate.scss";
@import "../../styles/mixin.scss";

@media (min-width: 961px) {
  .nav_box::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */
  }

  .nav_b {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: transparent;
  }

  .nav_box {
    width: 100%;
    margin: 0 auto;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;

    .nav_box_left {
      height: 100%;
      display: flex;
      align-items: center;
    }

    .nav_logo_text {
      color: #000;
      font-family: HarmonyOS Sans;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      /* 100% */
    }

    .nav_logo {
      display: block;
      width: 123px;
      cursor: pointer;
    }

    .nav_logo:hover {
      animation: scale 0.4 forwards;
    }

    .nav_content {
      display: flex;
      align-items: center;
      margin-left: 50px;
      // border-bottom: 1px solid rgba(222, 219, 229, 0.2);

      .nav_item {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 20px 12px;
        @include font_color();
        font-family: HarmonyOS Sans SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        position: relative;

        .fire {
          width: 24px;
          margin-left: 4px;
        }

        .select_down {
          width: 12px;
          margin-left: 8px;
        }

        .nav_item_child_posi {
          position: absolute;
          left: 50%;
          transform: translateX(-40%);
          top: 50px;
          display: none;

          .nav_item_child_placeholder {
            width: 100%;
            height: 20px;
          }
        }

        .nav_item_child_box {
          border-radius: 16px;
          padding: 12px;
          @include earn_content_bg();
          @include nav_border();

          .nav_item_child {
            padding: 0px 15px;
            min-width: 200px;
            white-space: nowrap;
            height: 36px;
            @include earn_content_bg();
            @include font_color();
            font-family: HarmonyOS Sans SC;
            font-size: 14px;
            font-weight: 500;
            line-height: 14px;
            line-height: 36px;
            text-align: left;
            margin-bottom: 8px;
            border-radius: 12px;
          }


          .nav_item_child:hover {
            @include wallet_act_bg();
          }

          .nav_item_child_active {
            // @include wallet_act_bg();
          }

          .nav_item_child:last-child {
            margin-bottom: 0;
          }
        }

      }

      .nav_item:hover {
        animation: scale1 0.4 forwards;

        .nav_item_child_posi {
          display: block;
        }
      }

      .nav_item_active {
        font-weight: bold;
        // border-bottom: 2px solid #FFEC13;
        // color: #fff;
      }

      .nav_item:last-child {
        margin-right: 0;
      }
    }

    .frame_img {
      margin-right: 20px;
      width: 24px;
      cursor: pointer;
    }

    .nav_right {
      display: flex;
      align-items: center;

      .switch_mode {
        @include font_color();
        width: 60px;
        height: 30px;
      }

      .setting_img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .nav_box_bg {
    @include bg_color3();
  }
}


@media (max-width: 960px) {
  .nav_box::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */
  }

  .nav_b {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: transparent;
  }


  .nav_box {
    width: 100%;
    margin: 0 auto;
    height: 48px;
    display: flex;
    align-items: center;
    @include bg_color3();
    justify-content: space-between;
    padding: 0 16px;
    @include crosschain_settings_tab_border();


    .nav_box_left {
      height: 100%;
      display: flex;
      align-items: center;
    }

    .nav_logo_text {
      color: #000;
      font-family: HarmonyOS Sans;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      /* 100% */
    }

    .nav_logo {
      display: block;
      width: 96px;
      cursor: pointer;
    }

    .nav_logo:hover {
      animation: scale 0.4 forwards;
    }

    .nav_content {
      display: flex;
      align-items: center;
      margin-left: 50px;
      display: none;
      // border-bottom: 1px solid rgba(222, 219, 229, 0.2);

      .nav_item {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 20px 12px;
        @include font_color();
        font-family: HarmonyOS Sans SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        position: relative;

        .select_down {
          width: 12px;
          margin-left: 8px;
        }

        .nav_item_child_box {
          position: absolute;
          left: 0;
          top: 50px;
          background-color: #fff;
          display: none;

          .nav_item_child {
            padding: 17px 20px;
            min-width: 200px;
            background-color: #fff;
            color: #000;
            font-family: HarmonyOS Sans SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px;
            /* 100% */
          }

          .nav_item_child_active {
            background-color: #1F1F1F;
            color: #fff;
          }
        }

      }

      .nav_item:hover {
        animation: scale1 0.4 forwards;

        .nav_item_child_box {
          display: block;
        }
      }

      .nav_item_active {
        font-weight: bold;
        // border-bottom: 2px solid #FFEC13;
        // color: #fff;
      }

      .nav_item:last-child {
        margin-right: 0;
      }
    }

    .frame_img {
      margin-right: 20px;
      width: 24px;
      cursor: pointer;
    }

    .nav_right {
      display: flex;
      align-items: center;

      .menu {
        width: 24px;
        margin-left: 8px;
      }

      .switch_mode {
        @include font_color();
        width: 60px;
        height: 30px;
      }

      .setting_img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .nav_box_bg {
    @include bg_color3();
  }

  .menu_box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    @include bg_color();

    .menu_head {
      height: 64px;
      padding: 0 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include crosschain_settings_tab_border();

      .menu_logo {
        width: 95px;
      }

      .menu_close {
        width: 24px;
      }
    }

    .menu_content {
      padding: 24px;
      height: calc(100% - 64px);
      overflow-y: scroll;

      .menu_link {
        font-family: HarmonyOS Sans SC;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        @include crosschain_color();
        padding: 16px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .menu_link_top {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .menu_link_bot {
          width: 100%;
          padding-top: 16px;

          .menu_link_child {
            height: 44px;
            padding: 0 16px;
            font-family: HarmonyOS Sans SC;
            font-size: 14px;
            font-weight: 400;
            line-height: 19.6px;
            text-align: left;
            color: #9597A8;
            line-height: 44px;
          }
        }

        .menu_switch_theme {

          display: flex;
          align-items: center;
          padding: 4px;
          border-radius: 100px;
          @include switch_theme_bg();

          .theme {
            width: 28px;
            margin-right: 12px;
          }

          .theme:last-child {
            margin-right: 0;
          }
        }




        .fire {
          width: 24px;
          margin-left: 4px;
        }
        .right_hide {
          width: 20px;
        }
      }

      .menu_link_child {
        flex-direction: column;
      }

      .new_setting_item2 {
        padding-left: 28px;
        @include rangePicker-svg-color();
        font-family: "HarmonyOS Sans SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        .new_setting_item2_text {
          margin-bottom: 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .check {
            width: 20px;
          }
        }

        .new_setting_item2_text:last-child {
          margin-bottom: 0;
        }
      }

      .menu_line {
        margin: 8px 0;
        width: 100%;
        height: 1px;
        @include crosschain_settings_tab_border();
      }
    }
  }
}