@import "../../styles/mixin.scss";
@import "../../styles/animate.scss";
@import "../../styles/fade-animate.scss";

@media (min-width: 961px) {

  .browser_notification {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;

    .browser_notification_mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba($color: #000000, $alpha: 0.5);
      z-index: 10000;
    }

    .browser_notification_content {
      z-index: 10001;
      position: absolute;
      top: 0;
      right: 0;
      width: 360px;
      height: 100vh;
      @include challengeDetail_data_bg_color();

      .browser_notification_title {
        width: 100%;
        height: 56px;
        padding: 0 24px;
        font-family: HarmonyOS Sans SC;
        font-size: 18px;
        font-weight: bold;
        line-height: 21.1px;
        text-align: left;
        @include earn_font_color();
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid;
        @include browser_notification_border();

        .close {
          cursor: pointer;
          width: 24px;
        }
      }

      .browser_notification_item_box {
        width: 100%;
        height: calc(100vh - 56px);
        overflow-y: scroll;

        .browser_notification_item {
          width: calc(100% - 48px);
          margin: 0 auto;
          padding: 24px 0;
          border-bottom: 1px solid;
          @include browser_notification_border();

          .browser_notification_item_title {
            font-family: HarmonyOS Sans Bold;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            text-align: left;
            @include earn_font_color();
            position: relative;

            .browser_notification_item_title_dot {
              position: absolute;
              width: 6px;
              height: 6px;
              top: 50%;
              transform: translateY(-50%);
              left: -13px;
              background-color: #F5395E;
              border-radius: 10px;
            }
          }

          .browser_notification_item_text {
            margin-top: 8px;
            font-family: HarmonyOS Sans SC;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            @include earn_font_color();
          }

          .browser_notification_item_text2 {
            font-family: HarmonyOS Sans SC;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            margin-top: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #9597A8;

            div {
              cursor: pointer;
              font-weight: bold;
              color: #673CD8;
            }
          }
        }
      }

      .browser_notification_nodata {
        height: 181px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include earn_font_color();
        font-family: HarmonyOS Sans SC;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        padding-top: 68px;

        .nodata_not {
          width: 64px;
          margin-bottom: 9px;
        }
      }
    }
  }
}

@media (max-width: 960px) {


  .browser_notification {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;

    .browser_notification_mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba($color: #000000, $alpha: 0.5);
      z-index: 10000;
    }

    .browser_notification_content {
      z-index: 10001;
      position: absolute;
      top: 0;
      right: 0;
      width: 100vw;
      height: 100vh;
      @include challengeDetail_data_bg_color();

      .browser_notification_title {
        width: 100%;
        height: 56px;
        padding: 0 24px;
        font-family: HarmonyOS Sans SC;
        font-size: 18px;
        font-weight: bold;
        line-height: 21.1px;
        text-align: left;
        @include earn_font_color();
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid;
        @include browser_notification_border();

        .close {
          cursor: pointer;
          width: 24px;
        }
      }

      .browser_notification_item_box {
        width: 100%;
        height: calc(100vh - 56px);
        overflow-y: scroll;

        .browser_notification_item {
          width: calc(100% - 48px);
          margin: 0 auto;
          padding: 24px 0;
          border-bottom: 1px solid;
          @include browser_notification_border();

          .browser_notification_item_title {
            font-family: HarmonyOS Sans Bold;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            text-align: left;
            @include earn_font_color();
            position: relative;

            .browser_notification_item_title_dot {
              position: absolute;
              width: 6px;
              height: 6px;
              top: 50%;
              transform: translateY(-50%);
              left: -13px;
              background-color: #F5395E;
              border-radius: 10px;
            }
          }

          .browser_notification_item_text {
            margin-top: 8px;
            font-family: HarmonyOS Sans SC;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.10000000149011612px;
            text-align: left;
            @include earn_font_color();
          }

          .browser_notification_item_text2 {
            font-family: HarmonyOS Sans SC;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            margin-top: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #9597A8;

            div {
              cursor: pointer;
              font-weight: bold;
              color: #673CD8;
            }
          }
        }
      }
    }
  }
}