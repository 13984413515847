@import "../../styles/mixin.scss";
@import "../../styles/animate.scss";
@import "../../styles/fade-animate.scss";
@media (min-width: 961px) {

  .footer_box {
    width: 100%;
    @include bg_color();

    .footer_content {
      width: 1200px;
      margin: 0 auto;
      padding: 60px 0;

      .footer_top {
        display: flex;
        justify-content: space-between;

        .footer_top_left {
          width: 60%;
          display: flex;
          position: relative;
          padding-bottom: 60px;

          .copy_right {
            position: absolute;
            left: 0;
            bottom: 0px;
            @include createChallenge_form_input_placeholder();
            font-family: HarmonyOS Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }

          .footer_top_left_content {
            width: 33.33%;

            .footer_top_left_item {
              @include rangePicker-svg-color();
              color: var(--Text-Secondary, #A5A6AC);
              font-family: HarmonyOS Sans;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              padding-bottom: 20px;
              cursor: pointer;
              transition: color 0.3s;
            }

            .footer_top_left_item:hover {
              @include font_color();
            }

            .footer_top_left_item:first-child {
              cursor: auto;
              padding-bottom: 20px;
              @include font_color();
              font-family: HarmonyOS Sans;
              font-size: 20px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              /* 100% */
            }
          }
        }

        .footer_top_right {
          .footer_logo {
            width: 171px;
          }

          .text1 {
            margin-top: 19px;
            color: #9658FF;
            font-family: "HarmonyOS Sans";
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: 40px;
            /* 125% */
            text-transform: uppercase;
          }

          .text2 {
            @include font_color();
            font-family: "HarmonyOS Sans";
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: 40px;
            text-transform: uppercase;
          }

          .footer_link_box {
            display: flex;
            align-items: center;
            margin-top: 134px;

            a {
              img {
                width: 40px;
                margin-right: 24px;
              }
            }
          }
        }
      }

      .footer_bot {
        display: flex;
        flex-direction: column;
        align-items: center;

        .footer_bot_link_box {
          display: flex;
          align-items: center;

          .footer_bot_link {
            display: block;
            width: 48px;
            height: 48px;
            margin-right: 20px;
            border-radius: 8px;

            img {
              width: 48px;
            }
          }

          .footer_bot_link:last-child {
            margin-right: 0;
          }
        }

        .footer_bot_text {
          margin-top: 8px;
          width: 390px;
          color: var(--text-tertiary, #626365);
          text-align: center;
          font-family: HarmonyOS Sans SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          /* 128.571% */
        }
      }
    }
  }

  .hide_footer {
    // display: none;
    background-color: #B5DECC;

    .footer_content {
      border-top: none;
    }
  }

  .none_footer {
    display: none;
  }
}

@media (max-width: 960px) {

  .footer_box {
    width: 100%;
    @include bg_color();

    .footer_content {
      width: 100%;
      margin: 0 auto;
      padding: 60px 0;

      .footer_top {
        padding: 0 20px;

        .footer_top_left {
          width: 100%;
          position: relative;
          padding-bottom: 60px;

          .copy_right {
            position: absolute;
            left: 0;
            bottom: 0px;
            @include createChallenge_form_input_placeholder();
            font-family: HarmonyOS Sans;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
          }

          .footer_top_left_content {
            width: 100%;

            .footer_top_left_item {
              @include rangePicker-svg-color();
              color: var(--Text-Secondary, #A5A6AC);
              font-family: HarmonyOS Sans;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              padding-bottom: 20px;
            }

            .footer_top_left_item:first-child {
              padding-bottom: 20px;
              @include font_color();
              font-family: HarmonyOS Sans;
              font-size: 20px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;

              display: flex;
              align-items: center;
              justify-content: space-between;

              img {
                width: 20px;
              }

              /* 100% */
            }
          }
        }

        .footer_top_right {
          .footer_logo {
            width: 141px;
          }

          .text1 {
            margin-top: 16px;
            color: #9658FF;
            font-family: HarmonyOS Sans;
            font-size: 20px;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: left;


            display: flex;
            align-items: center;
          }

          .text2 {
            @include font_color();
            padding-left: 5px;
            font-family: HarmonyOS Sans;
            font-size: 20px;
            font-weight: 500;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: left;

          }

          .footer_link_box {
            display: flex;
            align-items: center;
            margin: 24px 0;

            a {
              img {
                width: 48px;
                margin-right: 24px;
              }
            }
          }
        }
      }

      .footer_bot {
        display: flex;
        flex-direction: column;
        align-items: center;

        .footer_bot_link_box {
          display: flex;
          align-items: center;

          .footer_bot_link {
            display: block;
            width: 48px;
            height: 48px;
            margin-right: 20px;
            border-radius: 8px;

            img {
              width: 48px;
            }
          }

          .footer_bot_link:last-child {
            margin-right: 0;
          }
        }

        .footer_bot_text {
          margin-top: 16px;
          width: 100%;
          padding: 0 20px;
          color: var(--text-tertiary, #626365);
          text-align: center;
          font-family: HarmonyOS Sans SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          /* 128.571% */
        }
      }
    }
  }

  .hide_footer {
    // display: none;
    background-color: #B5DECC;

    .footer_content {
      border-top: none;
    }
  }

  .none_footer {
    display: none;
  }
}