@import "../src/font/index.scss";

#okx-inject {
  display: none;
}

.App {
  // display: flex;
  height: 100vh;
  // padding-top: 64px;
  // padding-bottom: 0.1px;
  overflow-y: scroll;
  // overflow: hidden;

  .content {
    width: 100%;
    // min-height: calc(100vh - 92px);
    // overflow-y: scroll;
    // padding: 0 20px;
  }
}

.ant-message {
  .ant-message-notice {
    .ant-message-notice-content {
      padding: 22px 18px;
      // padding: 22px 58px 22px 18px;
      // min-width: 350px;
      box-shadow: 0px 0px 46px 8px rgba(228, 232, 240, 0.9);
      border-radius: 15px;
      text-align: left;
      position: relative;

      .ant-message-custom-content {
        font-size: 18px;
        font-family: HarmonyOS Sans;
        font-weight: 500;
        color: #333333;

        .anticon-exclamation-circle {
          color: #f4c945;
          font-size: 20px;
          margin-right: 13px;
        }

        .message_close {
          width: 26px;
          height: 26px;
          position: absolute;
          right: 17px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
        }
      }
    }
  }
}

.ant-picker {
  height: 48px;
  border: 1px solid #ccc;
}

.ant-picker:hover,
.ant-picker-focused {
  border: 1px solid #ccc !important;
  border-color: #ccc !important;
}

.ant-picker-focused {
  border-color: #ccc !important;
}

.ant-picker-range .ant-picker-active-bar {
  background-color: transparent !important;
}

// @media (max-width: 1500px) {
//   .App {
//     display: flex;
//     background-color: #fff;
//     background-repeat: no-repeat;
//     background-size: 100% 100%;
//     min-height: 100vh;

//     .content {
//       width: calc(100% - 300px);
//       height: 100vh;
//       overflow-y: scroll;
//       padding: 0 20px;
//     }
//   }
// }

.event-toast-container {
  z-index: 100001 !important;
}

@media (max-width: 960px) {
  .App {
    display: block;
    // background-color: #18191C;
    height: unset;

    // padding-top: 48px;
    overflow-y: unset;

    .content {
      width: 100%;
      // padding-top: 80px;
    }
  }
}

* {
  scroll-margin-top: 100px;
}