@import "../../styles/mixin.scss";
@import "../../styles/animate.scss";

.setting {
  // width: 81px;
  font-size: 16px;
  font-family: Red Hat Display;
  font-weight: 500;
  color: #71808F;
  cursor: pointer;
  position: relative;
  margin-left: 16px;

  .lang_text_box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 28px;
    transition: background-color 0.3s;

    .lang_img {
      width: 16px;
    }

    // .lang_text {
    //   margin-right: 24px;
    // }

  }

  .lang_text_box:hover {
    @include crosschain_toinput_bg()
  }

  .setting_mask {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
  }

  .new_setting {
    @include challengeDetail_data_bg_color();
    position: absolute;
    right: -0px;
    top: 50px;
    padding: 16px;
    border-radius: var(--Radius-M-8px, 8px);
    border: 1px solid rgba(255, 255, 255, 0.05);
    @include modal_bg_box_shadow();
    width: 270px;

    .new_setting_item {
      @include font_color();
      font-family: "HarmonyOS Sans SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 0;

      .new_setting_item_left_box {
        display: flex;
        align-items: center;
      }
    }

    .new_setting_item2 {
      padding-left: 28px;
      @include rangePicker-svg-color();
      font-family: "HarmonyOS Sans SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      .new_setting_item2_text {
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .check {
          width: 20px;
        }
      }

      .new_setting_item2_text:last-child {
        margin-bottom: 0;
      }
    }

    .new_setting_item_left_img {
      width: 20px;
      margin-right: 8px;
    }

    .right_hide {
      width: 20px;
    }

    .switch_theme {
      display: flex;
      align-items: center;
      padding: 4px;
      border-radius: 100px;
      @include switch_theme_bg();

      .theme {
        width: 28px;
        margin-right: 12px;
      }

      .theme:last-child {
        margin-right: 0;
      }
    }
  }

  .setting_menu {
    z-index: 11;
    position: absolute;
    width: 340px;
    // top: 48px;
    // left: -160px;
    padding: 25px;
    background-color: #fff;

    .setting_title {
      color: #000;
      font-family: HarmonyOS Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      /* 100% */
      margin-bottom: 20px;
    }


    .setting_title2 {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: 24px;
        margin-left: 8px;
      }
    }

    .slippage_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 25px;

      .slippage_item {
        width: 68px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border: 2px solid #000;
        box-shadow: 3px 3px 0px #000;
        padding: 0 12px;
        color: #000;
        text-align: center;
        font-family: PingFang HK;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        /* 100% */
        cursor: pointer;
      }

      .slippage_item_act {
        background-color: #B5DECC;
      }
    }

    .setting_tran {
      display: flex;
      align-items: center;
      color: #000;
      font-family: HarmonyOS Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      margin-bottom: 25px;
      /* 100% */

      input {
        width: 100px;
        height: 32px;
        text-align: center;
        border: 1px solid rgba($color: #000000, $alpha: 0.3);
        margin-right: 15px;
      }
    }
  }
}

@media (max-width: 960px) {
  .setting_ {
    width: unset;
    height: 17px;
    padding: 0px;
    background-color: transparent;
    border-radius: 0px;
    font-size: 14px;
    font-family: Red Hat Display;
    font-weight: 500;
    color: #71808F;
    line-height: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    position: relative;
    // box-sizing: border-box;

    .lang_text_box {
      padding: 0px;
    }

    .lang_text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .setting_icon {
      width: 9px;
      height: 7px;
      margin-left: 6px;
    }

    .setting_mask {
      position: fixed;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100vw;
      height: 100vh;
      z-index: 10;
    }

    .setting_menu {
      z-index: 11;
      position: absolute;
      width: 100%;
      top: -80px;
      left: 0px;
      background-color: #ecedf0;

      .setting_menu_item {
        padding: 5px 18px 5px 13px;
        width: 100%;
        text-align: left;
        // box-sizing: border-box;
      }

      .setting_menu_item:first-child {
        padding-top: 10px;
      }

      .setting_menu_item:last-child {
        padding-bottom: 10px;
      }

      .setting_menu_item_action {
        // background-color: #EAE8EF;
        // color: #6647BE;
      }
    }
  }
}