@import "../../styles/mixin.scss";

@media (min-width: 961px) {

    .relative {
        .search_coin {
            margin-right: 16px;
            .select_token_img_box {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                border-radius: 100px;
                transition: background-color 0.3s;
            }

            .select_token_img {
                width: 24px;
                height: 24px;
            }

            .select_token_img_box:hover {
                @include transaction_position_bg();
            }
        }
    }


    .SearchToken_content {
        position: absolute;
        width: 400px;
        @include search_coin_bg();
        padding: 24px 16px;
        border-radius: 16px;
        right: 0px;
        top: 60px;


        .SearchToken_content_search {
            position: relative;
            padding: 0 8px;

            img {
                position: absolute;
                left: 20px;
                top: 50%;
                transform: translateY(-50%);
                width: 24px;
            }

            input {
                outline: none;
                background-color: transparent;
                height: 100%;
                height: 40px;
                width: 100%;
                border-radius: 12px;
                padding: 0 28px 0 45px;
                //styleName: 14/Body Regular 📌;
                font-family: HarmonyOS Sans;
                font-size: 14px;
                font-weight: 700;
                line-height: 20px;
                text-align: left;
                // border: 1px solid var(--text-icon-01, #FFFFFF)
                @include search_coin_input_bg();
                @include search_coin_input_outline();
                @include earn_font_color();
            }
            input::placeholder {
                color: #9597AB; /* 占位符文本的颜色 */
            }

          input:focus {
            outline: 1px solid #673CD8;
          }
        }

        .SearchToken_content_option_head {
            margin: 24px 0 0;
            padding: 0 8px;
            font-family: HarmonyOS Sans Bold;
            font-size: 14px;
            font-weight: 700;
            line-height: 16.41px;
            text-align: left;
            @include earn_font_color();
        }

        .SearchToken_content_item_srcoll {
            max-height: 300px;
            overflow-y: scroll;
            margin-top: 16px;
        }

        .SearchToken_content_item {
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 8px;
            border-radius: 12px;
            font-family: HarmonyOS Sans;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            text-align: left;
            @include font_color();
            margin-bottom: 0px;
            cursor: pointer;
        }

        .SearchToken_content_item:last-child {
            margin-bottom: 0;
        }

        .SearchToken_content_item_n {
            display: flex;
            align-items: center;
        }

        .SearchToken_content_item_right {
            font-family: HarmonyOS Sans Bold;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            text-align: right;
            @include earn_font_color();

            .g {
                font-family: HarmonyOS Sans;
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                color: #22BA71 !important;
            }

            .r {
                font-family: HarmonyOS Sans;
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                color: #F5395E !important;
            }
        }

        .SearchToken_content_item:hover {
            background-color: #9597A81A;
        }

        .SearchToken_content_item_act {
            // background-color: #9597A81A;
        }

        .icon {
            border-radius: 100px;
            width: 28px;
            margin-right: 8px;
        }
    }
}

@media (max-width: 960px) {
    
    .relative {
        display: flex;
        align-items: center;
        justify-content: center;
        .search_coin {
            margin-right: 8px;
            .select_token_img_box {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                height: 30px;
                border-radius: 100px;
                transition: background-color 0.3s;
            }

            .select_token_img {
                width: 24px;
                height: 24px;
            }

            .select_token_img_box:hover {
                @include transaction_position_bg();
            }
        }
    }

    .SearchToken_content {
        position: fixed;
        // width: 100%;
        width: calc(100vw - 40px);
        @include search_coin_bg();
        padding: 24px 16px;
        border-radius: 8px;
        left: 20px;
        top: 60px;
        margin-bottom: 0;


        .SearchToken_content_search {
            position: relative;
            padding: 0 8px;

            img {
                position: absolute;
                left: 20px;
                top: 50%;
                transform: translateY(-50%);
                width: 20px;
            }

            input {
                outline: none;
                background-color: transparent;
                height: 100%;
                height: 40px;
                width: 100%;
                border-radius: 12px;
                padding: 0 28px 0 45px;
                //styleName: 14/Body Regular 📌;
                font-family: HarmonyOS Sans;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                @include deriwadv_bg();
                @include createChallenge_form_input_placeholder();
            }
        }

        .SearchToken_content_option_head {
            margin: 24px 0 0;
            padding: 0 8px;
            font-family: HarmonyOS Sans Bold;
            font-size: 14px;
            font-weight: 700;
            line-height: 16.41px;
            text-align: left;
            @include earn_font_color();
        }


        .SearchToken_content_item_srcoll {
            max-height: 360px;
            overflow-y: scroll;
            margin-top: 16px;
        }

        .SearchToken_content_item {
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 8px;
            border-radius: 12px;
            font-family: HarmonyOS Sans;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            text-align: left;
            @include font_color();
            margin-bottom: 0px;
            cursor: pointer;
        }

        .SearchToken_content_item:last-child {
            margin-bottom: 0;
        }

        .SearchToken_content_item_n {
            display: flex;
            align-items: center;
        }

        .SearchToken_content_item_right {
            font-family: HarmonyOS Sans Bold;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            text-align: right;
            @include earn_font_color();

            .g {
                font-family: HarmonyOS Sans;
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                color: #22BA71 !important;
            }

            .r {
                font-family: HarmonyOS Sans;
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                color: #F5395E !important;
            }
        }

        .SearchToken_content_item:hover {
            background-color: #9597A81A;
        }

        .SearchToken_content_item_act {
            // background-color: #9597A81A;
        }

        .icon {
            border-radius: 100px;
            width: 28px;
            margin-right: 8px;
        }
    }
}