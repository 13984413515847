@media (min-width: 961px) {

  .selectchaincross {
    position: relative;

    .title {
      color: #000;
      font-family: HarmonyOS Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 25px;
      line-height: 14px;

    }
  }

  .selectchaincross_box {
    width: 58px;
    height: 40px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 40px;
    background-color: rgba(240, 185, 11, 0.16) !important;
    color: #000;
    font-family: HarmonyOS Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    cursor: pointer;
    /* 100% */

    .seletchain_icon {
      width: 17px;
      margin-right: 5px;
    }

    .seletchain_down {
      width: 12px;
      margin-left: 5px;
    }

    .seletchain_down_act {
      transform: rotate(180deg)
    }
  }

  .selectchain_deriw_bg {
    background-color: #A729D629 !important;
  }

  .selectchain_dot {
    position: absolute;
    top: 45px;
    right: 0;
    background-color: #fff;
    border: 2px solid #000;
    box-shadow: 3px 3px 0px #000;
    padding: 10px 0;

    .selectchain_dot_item {
      width: 200px;
      display: flex;
      align-items: center;
      color: #000;
      font-family: HarmonyOS Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      cursor: pointer;
      padding: 5px 12px;

      .seletchain_dot_icon {
        width: 17px;
        margin-right: 5px;
      }
    }
  }
}

@media (max-width: 960px) {
  .selectchaincross {
    position: relative;
  }

  .selectchaincross_box {
    width: 52px;
    height: 30px;
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    background-color: rgba(240, 185, 11, 0.16) !important;
    color: #000;
    font-family: HarmonyOS Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    cursor: pointer;
    /* 100% */

    .seletchain_icon {
      width: 17px;
      margin-right: 5px;
    }

    .seletchain_down {
      width: 12px;
      margin-left: 5px;
    }

    .seletchain_down_act {
      transform: rotate(180deg)
    }
  }

  .selectchain_deriw_bg {
    background-color: #A729D629 !important;

  }

  .selectchain_dot {
    position: absolute;
    top: 45px;
    right: 0;
    background-color: #fff;
    border: 2px solid #000;
    box-shadow: 3px 3px 0px #000;
    padding: 10px 0;

    .selectchain_dot_item {
      width: 200px;
      display: flex;
      align-items: center;
      color: #000;
      font-family: HarmonyOS Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      cursor: pointer;
      padding: 5px 12px;

      .seletchain_dot_icon {
        width: 17px;
        margin-right: 5px;
      }
    }
  }
}