@import "../../styles/mixin.scss";
@media (min-width: 961px) {

  .walletconnect {
    position: relative;
  }

  .walletconnect_content {
    max-height: 300px;
    overflow-y: scroll;
  }

  .walletconnect_box {
    min-width: 96px;
    height: 40px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: var(--Radius-Full, 100px);
    @include wallet_act_bg();

    // padding: 0 12px;
    color: var(--Text-static-white, #FFF);
    font-family: HarmonyOS Sans Bold;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    cursor: pointer;
    text-align: center;
    position: relative;
    /* 100% */

    .walletconnect_box_dot {
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: red;
      top: 0px;
      right: 0px;
    }
    .seletchain_icon {
      width: 17px;
      margin-right: 5px;
    }

  }

  .walletconnect_box:hover {
    transform: scale(1.05);
  }
  .walletconnect_active {
    @include walletconnect_active_bg();
  }


  .wallet_text {
    @include font_color();
  }

  .wallet-dropdown {
    width: 100%;
  }

  .walletconnect_box:hover {
    // background-color: #09e7b3;
    // box-shadow: 4px 4px 0px #000;
  }

  .walletconnect_dot {
    width: 300px;
    position: absolute;
    // top: 45px;
    right: 0px;
    top: 60px;
    @include challengeDetail_data_bg_color();
    border-radius: var(--Radius-M-8px, 8px);
    padding: 12px;
    @include modal_bg_box_shadow();

    .walletconnect_item_balance {
      margin-top: 16px;
      margin-bottom: 16px;
      border-radius: 12px;
      @include generate_bg_color();
      .walletconnect_item_balance_item {
        font-family: HarmonyOS Sans Bold;
        font-size: 20px;
        font-weight: 500;
        line-height: 23.44px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        @include earn_font_color();
        padding: 12px;

        .walletconnect_item_balance_item_top {
          display: flex;
          align-items: center;
          font-family: HarmonyOS Sans SC;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.41px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color:#9597AB;

          .icon_box {
            width: 20px;
            height: 20px;
            border-radius: 20px;
            @include logo_s_bg2();
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 4px;

            img {
              width: 12px;
            }
          }
        }
      }
      .walletconnect_item_balance_item_mid {
        width: 100%;
        height: 1px;
        background-color: #9597A80D;
      }
    }


    .walletconnect_item_account_box {
      .walletconnect_item_account_line_mid {
        width: 10px;
        margin: -3px 0 -3px 11px;
      }
      .walletconnect_item_account_line {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .walletconnect_item_account_line_left {
          display: flex;
          align-items: center;
          .walletconnect_item_account_line_left_img_box {
            width: 32px;
            height: 32px;
            border-radius: 32px;
            @include logo_s_bg();
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;

            img {
              width: 20px;
            }
          }

          .walletconnect_item_account_line_left_top {
            font-family: HarmonyOS Sans SC;
            font-size: 12px;
            font-weight: 500;
            line-height: 14.06px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #9597AB;
            .w {
              @include earn_font_color();
              padding-right: 4px;
            }

            .td {
              border-bottom: 1px dashed #9597AB;
            }
          }
          .walletconnect_item_account_line_left_bot {
            font-family: HarmonyOS Sans SC;
            font-size: 14px;
            font-weight: 500;
            line-height: 16.41px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            margin-top: 4px;
            @include earn_font_color();
          }
        }
        .walletconnect_item_account_line_right {
          display: flex;
          align-items: center;

          .icon_box {
            width: 32px;
            height: 32px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--btn-bg02, #9597A81A);
            margin-left: 8px;
            cursor: pointer;

            img {
              width: 20px;
            }
          }
        }
      }
    }

    .walletconnect_item_account_box_nomne {
      margin-bottom: 10px;
    }

    .walletconnect_item {
      padding: 10px 0px;
      white-space: nowrap;
      // height: 40px;
      display: flex;
      align-items: center;
      /* 100% */
      cursor: pointer;
      color: #9597AB;
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.41px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      position: relative;

      .walletconnect_item_dot {
        position: absolute;
        background-color: red;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
      }

      .walletconnect_item_left {
        display: flex;
        align-items: center;

        .icon {
          width: 20px;
          margin-right: 8px;
        }
      }

    }

    .walletconnect_item_active {
      cursor: pointer;
      padding: 8px 16px;
      display: flex;
      align-items: center;
      width: 240px;
      height: 56px;
      @include font_color();
      font-family: "HarmonyOS Sans";
      font-size: 16px;
      font-weight: 600;
    }

    // .walletconnect_item:hover,
    // .walletconnect_item_active:hover {
    //   border-radius: 4px;
    //   @include option_hover_bg();
    // }

    .walletconnect_item_other {
      padding: 12px 16px 8px;
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.41px;
      text-align: left;
      color: #9597A8;
    }

    .walletconnect_item2 {
      background-color: #1F1F1F;
      color: #fff;
    }
  }

  .walletconnect_float {
    .title {
      color: #000;
      font-family: HarmonyOS Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 25px;
      line-height: 14px;
    }

    .text {
      color: rgba($color: #000000, $alpha: 0.6);
      font-family: HarmonyOS Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      margin-bottom: 10px;
      /* 128.571% */
    }

    .wallet_item {
      width: 100%;
      height: 44px;
      border: 1px solid #000;
      display: flex;
      align-items: center;
      padding: 0 13px;
      color: #000;
      font-family: HarmonyOS Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      margin-bottom: 12px;
      cursor: pointer;
      /* 100% */

      img {
        width: 24px;
        margin-right: 12px;
      }
    }
  }

  .walletconnect_wallet {
    position: absolute;
    // top: 45px;
    right: 0;
    background-color: #fff;

    // width: 340px;
    // padding: 25px;

    .title {
      color: #000;
      font-family: HarmonyOS Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 25px;
      line-height: 14px;
    }

    .text {
      color: rgba($color: #000000, $alpha: 0.6);
      font-family: HarmonyOS Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      margin-bottom: 30px;
      /* 128.571% */
    }

    .wallet_item {
      width: 100%;
      height: 44px;
      border: 1px solid #000;
      display: flex;
      align-items: center;
      padding: 0 13px;
      color: #000;
      font-family: HarmonyOS Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      margin-bottom: 12px;
      cursor: pointer;
      /* 100% */

      img {
        width: 24px;
        margin-right: 12px;
      }
    }
  }
}

@media (max-width: 960px) {


  .walletconnect {
    position: relative;
  }


  .walletconnect_content {
    max-height: 400px;
    overflow-y: scroll;
  }

  .walletconnect_box {
    // min-width: 96px;
    // height: 40px;
    // margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: var(--Radius-Full, 100px);
    // background-color: #673CD8 !important;
    // padding: 0 12px;
    color: var(--Text-static-white, #FFF);
    font-family: HarmonyOS Sans Bold;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    cursor: pointer;
    text-align: center;
    /* 100% */

    .seletchain_icon {
      width: 17px;
      margin-right: 5px;
    }

  }

  .walletconnect_active {
    // @include walletConnect_bg()
  }


  .wallet_text {
    @include font_color();
  }

  .wallet-dropdown {
    width: 100%;
    height: 28px;
    border-radius: 32px;
    // background-color: #673CD8;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-left: 8px;
    padding: 0;

    // .wallet-dropdown-connected-m {
    //   background: linear-gradient(116.41deg, #FF00B8 -12.85%, #7751FF 76.19%);
    //   width: 95px;
    //   height: 32px;
    //   padding: 6px, 24px, 6px, 24px
    //   border-radius: 100px
    //   gap: 8px

    // }

    .active_img {
      padding: 0;
    }

    .nactive_img {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      border-radius: 32px;
      padding: 0 8px;
      font-family: HarmonyOS Sans;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      color: #fff;
      background: linear-gradient(116.41deg, #FF00B8 -12.85%, #7751FF 76.19%);

    }
  }


  .wallet-dropdown-m {
    width: 28px;
    background-color: #673CD8 !important;
  }

  .walletconnect_box:hover {
    // background-color: #09e7b3;
    // box-shadow: 4px 4px 0px #000;
  }

  .walletconnect_dot {
    position: fixed;
    // top: 45px;
    left: 0;
    bottom: 0px;
    @include home_referrals_info_item_bg();
    border-radius: 16px 16px 0 0;
    padding: 24px;
    width: 100%;


    .walletconnect_item_balance {
      margin-top: 16px;
      margin-bottom: 16px;
      border-radius: 12px;
      @include generate_bg_color();
      .walletconnect_item_balance_item {
        font-family: HarmonyOS Sans Bold;
        font-size: 20px;
        font-weight: 500;
        line-height: 23.44px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        @include earn_font_color();
        padding: 12px;

        .walletconnect_item_balance_item_top {
          display: flex;
          align-items: center;
          font-family: HarmonyOS Sans SC;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.41px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color:#9597AB;

          .icon_box {
            width: 20px;
            height: 20px;
            border-radius: 20px;
            @include logo_s_bg2();
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 4px;

            img {
              width: 12px;
            }
          }
        }
      }
      .walletconnect_item_balance_item_mid {
        width: 100%;
        height: 1px;
        background-color: #9597A80D;
      }
    }


    .walletconnect_item_account_box {
      .walletconnect_item_account_line_mid {
        width: 10px;
        margin: -3px 0 -3px 11px;
      }
      .walletconnect_item_account_line {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .walletconnect_item_account_line_left {
          display: flex;
          align-items: center;
          .walletconnect_item_account_line_left_img_box {
            width: 32px;
            height: 32px;
            border-radius: 32px;
            @include logo_s_bg();
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;

            img {
              width: 20px;
            }
          }

          .walletconnect_item_account_line_left_top {
            font-family: HarmonyOS Sans SC;
            font-size: 12px;
            font-weight: 500;
            line-height: 14.06px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #9597AB;
            .w {
              @include earn_font_color();
              padding-right: 4px;
            }

            .td {
              border-bottom: 1px dashed #9597AB;
            }
          }
          .walletconnect_item_account_line_left_bot {
            font-family: HarmonyOS Sans SC;
            font-size: 14px;
            font-weight: 500;
            line-height: 16.41px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            margin-top: 4px;
            @include earn_font_color();
          }
        }
        .walletconnect_item_account_line_right {
          display: flex;
          align-items: center;

          .icon_box {
            width: 32px;
            height: 32px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--btn-bg02, #9597A81A);
            margin-left: 8px;
            cursor: pointer;

            img {
              width: 20px;
            }
          }
        }
      }
    }

    .walletconnect_item_account_box_nomne {
      margin-bottom: 10px;
    }


    .walletconnect_item {
      padding: 8px 0px;
      white-space: nowrap;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
      /* 100% */
      cursor: pointer;

      color: #9597AB;
      font-family: HarmonyOS Sans SC;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      letter-spacing: 0em;
      position: relative;


      .walletconnect_item_dot {
        position: absolute;
        background-color: red;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
      }
      .walletconnect_item_left {
        display: flex;
        align-items: center;

        img {
          width: 20px;
          margin-right: 8px;
        }
      }

      img {
        width: 20px;
      }

    }

    .walletconnect_item_head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;

      .close {
        width: 24px;
      }

      .walletconnect_item_head_left {
        display: flex;
        align-items: center;

        font-family: HarmonyOS Sans Bold;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        @include font_color();

        .photo_box {
          width: 40px;
          height: 40px;
          border-radius: 40px;
          background-color: #673CD8;
          margin-right: 8px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 24px;
          }
        }

        .copy {
          width: 16px;
          margin-left: 8px;
        }
      }
    }

    .walletconnect_item:last-child {
      margin-bottom: 0;
    }

    .walletconnect_item_active_head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: HarmonyOS Sans Bold;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      @include font_color();
      margin-bottom: 20px;

      img {
        width: 24px;
      }
    }

    .walletconnect_item_active {
      cursor: pointer;
      @include crosschain_toinput_bg();
      padding: 8px 16px;
      display: flex;
      align-items: center;
      width: 100%;
      height: 64px;
      @include font_color();
      font-family: "HarmonyOS Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 8px;
      border-radius: 8px;
    }

    .walletconnect_item_other {
      padding: 12px 16px 8px;
      font-family: HarmonyOS Sans SC;
      font-size: 16px;
      font-weight: 500;
      line-height: 16.41px;
      text-align: left;
      color: #9597A8;
    }

    .walletconnect_item:hover,
    .walletconnect_item_active:hover {
      border-radius: 4px;
      @include option_hover_bg();
    }

    .walletconnect_item2 {
      background-color: #1F1F1F;
      color: #fff;
    }
  }

  .walletconnect_float {
    .title {
      color: #000;
      font-family: HarmonyOS Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 25px;
      line-height: 14px;
    }

    .text {
      color: rgba($color: #000000, $alpha: 0.6);
      font-family: HarmonyOS Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      margin-bottom: 10px;
      /* 128.571% */
    }

    .wallet_item {
      width: 100%;
      height: 44px;
      border: 1px solid #000;
      display: flex;
      align-items: center;
      padding: 0 13px;
      color: #000;
      font-family: HarmonyOS Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      margin-bottom: 12px;
      cursor: pointer;
      /* 100% */

      img {
        width: 24px;
        margin-right: 12px;
      }
    }
  }

  .walletconnect_wallet {
    position: absolute;
    // top: 45px;
    right: 0;
    background-color: #fff;

    // width: 340px;
    // padding: 25px;

    .title {
      color: #000;
      font-family: HarmonyOS Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 25px;
      line-height: 14px;
    }

    .text {
      color: rgba($color: #000000, $alpha: 0.6);
      font-family: HarmonyOS Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      margin-bottom: 30px;
      /* 128.571% */
    }

    .wallet_item {
      width: 100%;
      height: 44px;
      border: 1px solid #000;
      display: flex;
      align-items: center;
      padding: 0 13px;
      color: #000;
      font-family: HarmonyOS Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      margin-bottom: 12px;
      cursor: pointer;
      /* 100% */

      img {
        width: 24px;
        margin-right: 12px;
      }
    }
  }
}