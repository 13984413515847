.toggle {
  max-height: 0;
  overflow: hidden;
  animation: toggle .8s forwards;
}
@keyframes toggle
{
  from {max-height: 0}
  to {max-height: 100vh;}
}

.scale {
  animation: scale .4s forwards;
}
@keyframes scale
{
  from {transform: scale(1)}
  to {transform: scale(1.03)}
}

@keyframes scale1
{
  from {transform: scale(1)}
  to {transform: scale(1.1)}
}

@keyframes opacity
{
  from {opacity: 1}
  to {opacity: 0.8}
}

@keyframes rotate
{
  from {transform: rotate(0)}
  to {transform: rotate(360deg)}
}

// 外阴影
@keyframes shadow
{
  from {box-shadow: 0 0 0 #999;}
  to {box-shadow: 0 0 13px #999;}
}

@keyframes font
{
  from {font-weight: 100;}
  to {font-weight: 600;}
}
