@import "../../styles/mixin.scss";

@media (min-width: 961px) {

    .select_net_cross_menu {
        position: absolute;
        right: 0px;
        top: 60px;
        padding: 24px 16px;
        border-radius: var(--Radius-M-8px, 8px);
        @include crosschain_selectnetwork_bg();
        display: flex;

        .select_net_cross_menu_box {
            margin-right: 24px;

            // display: flex;
            .select_net_cross_menu_box_title {
                font-family: HarmonyOS Sans SC;
                font-size: 20px;
                font-weight: 400;
                line-height: 23px;
                letter-spacing: 0px;
                text-align: left;
                color: #A5A6AC;
                margin-bottom: 10px;
            }
        }

        .select_net_cross_menu_box:last-child {
            margin-right: 0;
        }

        .select_net_cross_menu_item {
            margin-bottom: 4px;
            width: 180px;
            height: 48px;
            padding: 8px 0px;
            display: flex;
            align-items: center;
            justify-content: space-between;


            .active_dot {
                width: 20px;
            }

            .left_box {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .icon_img {
                width: 24px;
                height: 24px;
                margin-right: 12px;
            }

            .text {
                font-family: HarmonyOS Sans SC;
                font-size: 16px;
                font-weight: 500;
                line-height: 19px;
                letter-spacing: 0px;
                text-align: left;
                @include crosschain_color();
            }

            .layer2 {
                @include crosschain_text_dis();
            }

            /* 150% */
        }

        .select_net_cross_menu_item:last-child {
            margin-bottom: 0;
        }
    }
}

@media (max-width: 960px) {
    .select_net_cross_menu {
        @include challengeDetail_data_bg_color();
        position: absolute;
        right: -60px;
        top: 20px;
        padding: 16px;
        border-radius: var(--Radius-M-8px, 8px);
        border: 1px solid rgba(255, 255, 255, 0.05);
        @include modal_bg_box_shadow();

        .select_net_cross_menu_item {
            margin-bottom: 4px;
            width: 200px;
            height: 40px;
            padding: 8px 0px;
            @include font_color();
            font-family: "HarmonyOS Sans SC";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;


            .active_dot {
                width: 20px;
            }

            .left_box {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .icon_img {
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }

            /* 150% */
        }

        .select_net_cross_menu_item:last-child {
            margin-bottom: 0;
        }
    }

    .model_close {
        width: 24px;
    }

    .network-dropdown-m {
        align-items: flex-end;

        .network-dropdown-m-box {
            width: 100%;
            @include crosschain_setting_bg();
            padding: 24px;
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;

            .model_title {
                font-family: HarmonyOS Sans SC;
                font-size: 20px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                @include font_color();
            }

            .model_close {
                width: 24px;
            }
        }

        .network_options_cross_m_box {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .network_options_cross_m {
            // width: calc(50% - 10px);
            height: 56px;
            padding: 12px 0;
            margin-top: 4px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .network_options_cross_m_left {
                font-family: HarmonyOS Sans SC;
                font-size: 16px;
                font-weight: 500;
                line-height: 19px;
                letter-spacing: 0px;
                text-align: left;
                @include font_color();
                display: flex;
                align-items: center;

                img {
                    width: 32px;
                    margin-right: 12px;
                }
            }

            .layer2{
                @include crosschain_text_dis();
            }

            .activeDot {
                width: 24px;
            }
        }

        .network-dropdown-m-title {
            font-family: HarmonyOS Sans SC;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0px;
            text-align: left;
            @include pagination_color();
            margin-top: 24px;
        }
    }
}