@import "../../styles/mixin.scss";
@import "../../styles/animate.scss";
@import "../../styles/fade-animate.scss";

@media (min-width: 961px) {

  .SettingsModal_mask {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    background-color: #000000B2;
  }

  .SettingsModal {
    @include crosschain_setting_bg();
    position: fixed;
    top: 0;
    right: 0;
    z-index: 11;
    width: 798px;
    height: 100vh;
    padding: 40px;

    .SettingsModal_close {
      position: absolute;
      top: 40px;
      right: 40px;
      cursor: pointer;
      width: 24px;
    }

    .SettingsModal_title {
      font-family: HarmonyOS Sans SC;
      font-size: 24px;
      font-weight: 500;
      line-height: 40px;
      letter-spacing: 0px;
      text-align: left;
      @include crosschain_color();
    }

    .SettingsModal_text1 {
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      color: #FFA91C;
      background-color: #FFA91C29;
      margin-top: 16px;
      padding: 12px 16px;
      border-radius: 8px;
    }

    .SettingsModal_text2 {
      margin-top: 16px;
      padding: 12px 16px;
      border-radius: 8px;
      @include crosschain_toinput_bg();


      font-family: HarmonyOS Sans Bold;
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: 0px;
      text-align: left;

      @include crosschain_color();

      div {
        margin-top: 6px;
        font-family: HarmonyOS Sans SC;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0px;
        text-align: left;
        color: #A5A6AC;
      }
    }

    .SettingsModal_tab_box {
      margin-top: 20px;
      // margin-top: 44px;
      @include crosschain_settings_tab_border();
      display: flex;
      align-items: center;

      .SettingsModal_tab {
        display: flex;
        align-items: center;
        height: 40px;
        margin-right: 30px;
        font-family: HarmonyOS Sans SC;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0px;
        text-align: left;
        cursor: pointer;
        @include crosschain_settings_tab_color();
      }

      .SettingsModal_tab_act {
        @include crosschain_color();
        border-bottom: 2px solid #673CD8;
      }
    }

    .SettingsModal_search {
      margin-top: 20px;
      position: relative;

      img {
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
      }


      input {
        // margin: 16px 0 24px;
        outline: none;
        background-color: transparent;
        height: 100%;
        height: 40px;
        width: 100%;
        border-radius: 8px;
        @include crosschain_selectcoin_input_border();
        padding: 0 28px 0 48px;
        //styleName: 14/Body Regular 📌;
        font-family: HarmonyOS Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        @include createChallenge_form_input_placeholder();
      }
    }

    .SettingsModal_table_head_box {
      height: calc(100vh - 80px - 40px - 61px - 60px - 40px - 40px);
      overflow-y: scroll;
    }

    .SettingsModal_table_head {
      margin-top: 24px;
      display: flex;
      align-items: center;

      .SettingsModal_table_head_item {
        width: 14%;
        font-family: HarmonyOS Sans SC;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: left;
        @include crosschain_settings_tab_color();
      }

      .SettingsModal_table_head_item:last-child {
        text-align: right;
      }

      .SettingsModal_table_td_item {
        display: flex;
        align-items: center;
        @include crosschain_color();

        .SettingsModal_table_td_item_img {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }

        .SettingsModal_table_td_item_text {
          width: 80px;
        }

        .SettingsModal_table_td_item_btn {
          width: 70px;
          height: 32px;
          padding: 8px 16px 8px 16px;
          border-radius: 8px;
          background-color: #673CD8;
          font-family: HarmonyOS Sans SC;
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
          text-align: center;
          color: #fff !important;
          cursor: pointer;
        }

        .SettingsModal_table_td_item_btn_dis {
          background-color: #FFFFFF14;
          color: #626365 !important;
        }
      }

      .SettingsModal_table_td_item1 {
        width: 20%;
      }

      .SettingsModal_table_td_item2 {
        width: 20%;
      }

      .SettingsModal_table_td_item3 {
        width: 20%;
      }

      .SettingsModal_table_td_item4 {
        width: 20%;
      }

      .SettingsModal_table_td_item5 {
        // width: 12%;
        width: 0;
      }
    }

    .SettingsModal_table_nodata {
      margin-top: 100px;
      display: flex;
      align-items: center;
      flex-direction: column;
      font-family: HarmonyOS Sans SC;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0px;
      text-align: left;
      @include crosschain_settings_tab_color();

      img {
        width: 112px;
      }
    }
  }

}

@media (max-width: 960px) {


  .SettingsModal_mask {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    background-color: #000000B2;
  }

  .SettingsModal {
    @include bg_color();
    position: fixed;
    top: 0;
    right: 0;
    z-index: 11;
    width: 100vw;
    height: 100vh;
    padding: 24px;

    .SettingsModal_close {
      position: absolute;
      top: 24px;
      left: 24px;
      cursor: pointer;
      width: 24px;
    }

    .SettingsModal_title {
      height: 48px;
      font-family: HarmonyOS Sans Bold;
      font-size: 18px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0px;
      text-align: center;
      @include font_color();
    }

    .SettingsModal_text1 {
      font-family: HarmonyOS Sans SC;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      color: #FFA91C;
      background-color: #FFA91C29;
      margin-top: 16px;
      padding: 9px 12px;
      border-radius: 8px;
    }

    .SettingsModal_text2 {
      margin-top: 8px;
      padding: 9px 12px;
      border-radius: 8px;
      @include crosschain_toinput_bg();
      font-family: HarmonyOS Sans Bold;
      font-size: 12px;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: 0px;
      text-align: left;

      @include crosschain_color();

      div {
        margin-top: 6px;
        font-family: HarmonyOS Sans SC;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0px;
        text-align: left;
        color: #A5A6AC;
      }
    }

    .SettingsModal_tab_box {
      margin-top: 16px;
      @include crosschain_settings_tab_border();
      display: flex;
      align-items: center;

      .SettingsModal_tab {
        display: flex;
        align-items: center;
        height: 40px;
        margin-right: 30px;
        font-family: HarmonyOS Sans SC;
        font-size: 14px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0px;
        text-align: left;
        cursor: pointer;
        @include crosschain_settings_tab_color();
      }

      .SettingsModal_tab_act {
        @include crosschain_color();
        border-bottom: 2px solid #673CD8;
      }
    }

    .SettingsModal_search {
      margin-top: 20px;
      position: relative;

      img {
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
      }


      input {
        // margin: 16px 0 24px;
        outline: none;
        background-color: transparent;
        height: 100%;
        height: 40px;
        width: 100%;
        border-radius: 8px;
        @include crosschain_selectcoin_input_border();
        padding: 0 28px 0 48px;
        //styleName: 14/Body Regular 📌;
        font-family: HarmonyOS Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        @include createChallenge_form_input_placeholder();
      }
    }

    .SettingsModal_table_head {
      margin-top: 24px;
      display: flex;
      align-items: center;

      .SettingsModal_table_head_item {
        width: 25%;
        font-family: HarmonyOS Sans SC;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: left;
        @include crosschain_settings_tab_color();
      }

      .SettingsModal_table_head_item:last-child {
        text-align: right;
      }
    }

    .SettingsModal_table_nodata {
      margin-top: 100px;
      display: flex;
      align-items: center;
      flex-direction: column;
      font-family: HarmonyOS Sans SC;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0px;
      text-align: left;
      @include crosschain_settings_tab_color();

      img {
        width: 112px;
      }
    }
  }
}