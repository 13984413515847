@import "../../styles/mixin.scss";

@media (min-width: 961px) {
  .setting_modal {
    .setting_modal_lable {
      margin-top: 24px;
      @include rangePicker-svg-color();
      font-family: "HarmonyOS Sans SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .hd_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 8px;
      position: relative;

      .hd_item {
        @include hd-bg();
        border-radius: var(--Radius-M-8px, 8px);
        cursor: pointer;
        width: calc((100% - 36px) / 4);
        height: 48px;
        @include rangePicker-svg-color();
        text-align: center;
        font-family: "HarmonyOS Sans SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .hd_item_act {
        @include font_color();
        border: 2px solid #673CD8;
      }

      input {
        width: 100%;
        height: 48px;
        border-radius: 8px;

        @include hd-bg();
        border: none;
        outline: none;
        padding: 0 80px 0 16px;

        @include font_color();
        font-family: "HarmonyOS Sans SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }

      .dot {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;
        @include font_color();
        font-family: "HarmonyOS Sans SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .btn {
      margin-top: 24px;
      border-radius: var(--Radius-Full, 100px);
      background-color: #673CD8;
      width: 100%;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 12px;
      color: var(--Text-static-white, #FFF);
      text-align: center;
      font-family: "HarmonyOS Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
    }
  }
}

@media (max-width: 960px) {

  .setting_modal {
    .setting_modal_lable {
      margin-top: 24px;
      @include rangePicker-svg-color();
      font-family: "HarmonyOS Sans SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .hd_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 8px;
      position: relative;

      .hd_item {
        @include hd-bg();
        border-radius: var(--Radius-M-8px, 8px);
        cursor: pointer;
        width: calc((100% - 36px) / 4);
        height: 48px;
        @include rangePicker-svg-color();
        text-align: center;
        font-family: "HarmonyOS Sans SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .hd_item_act {
        @include font_color();
        border: 2px solid #673CD8;
      }

      input {
        width: 100%;
        height: 48px;
        border-radius: 8px;

        @include hd-bg();
        border: none;
        outline: none;
        padding: 0 80px 0 16px;

        @include font_color();
        font-family: "HarmonyOS Sans SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }

      .dot {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;
        @include font_color();
        font-family: "HarmonyOS Sans SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .btn {
      margin-top: 24px;
      border-radius: var(--Radius-Full, 100px);
      background-color: #673CD8;
      width: 100%;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 12px;
      color: var(--Text-static-white, #FFF);
      text-align: center;
      font-family: "HarmonyOS Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
    }
  }
}