@import "../../../styles/mixin.scss";

.c-modal2 {
  .ant-modal-content {
    position: relative;
    @include cmodal2_bg();
    border-radius: 16px;
    box-shadow: none;

    .ant-modal-body {
      line-height: 1;
      border-radius: 16px;
      padding: 24px;
      @include cmodal2_bg();

      .c-modal__close {
        position: absolute;
        top: 24px;
        right: 16px;

        width: 24px;
        height: 24px;
        cursor: pointer;
        box-sizing: content-box;
        transition: transform 0.3s ease-in-out;

        &:hover {
          transform: rotate(180deg);
        }
      }

      .c-modal__title {
        @include font_color();
        font-family: "HarmonyOS Sans Bold";
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;

        display: flex;
        align-items: center;
      }

      .switch-network-modal {
        .switch-network-tips {
          font-family: NunitoSans-SemiBold;
          font-weight: 600;
        }

        .btn-wrap {
          font-family: NunitoSans-Bold;
          font-weight: 700;
        }

      }

      // .at-modal__btns {
      //   margin-top: 40px;
      //   margin-bottom: 20px;

      //   display: flex;
      //   justify-content: center;
      //   .ant-btn {
      //     min-width: 283px;
      //     height: 50px;
      //     font-size: 16px;
      //   }
      // }
    }
  }
}

.c-drawer {

  // .ant-drawer-content-wrapper {
  //   max-height: 80%;
  //   overflow-y: auto;
  // }
  .ant-drawer-header {
    padding: 16px 15px;
  }

  .ant-drawer-title {
    font-weight: bold;
  }

  .ant-drawer-body {
    padding: 0 15px 15px;
  }

  .ant-drawer-content {
    border-radius: 10px 10px 0 0;
  }
}


@media (max-width: 960px) {
  .c-modal2-down {
    width: 100vw !important;
    max-width: unset !important;
    top: unset !important;
    bottom: 0;
    left: 0;
    position: fixed !important;
    margin-bottom: 0 !important;

    .ant-modal-content {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
}