@import "../../styles/animate.scss";
@import "../../styles/mixin.scss";

.nodata_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include transaction_secondary_font_color2();

  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;

  padding: 100px 0;

  img {
    width: 47px;
    margin-bottom: 10px;
  }
}