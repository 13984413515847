@import "../../styles/mixin.scss";
@import "../../styles/animate.scss";
@import "../../styles/fade-animate.scss";


@media (min-width: 961px) {
  .secondary_metamask_icon {
    width: 20px;
    margin-right: 8px;
  }
  .cancel_confirm_tx_secondary {
    padding: 12px 0px 0;
    
    .confirm_tx_line {
      // @include earn_font_color();
      color: #9597AB;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.41px;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      text-align: left;
    }

    .confirm_tx_line3 {
      color: #9597AB;
      display: flex;
      align-items: center;
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.41px;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      text-align: left;
      margin-top: 20px;

      .yes {
        color: #59B677;
      }
    }

    .confirm_tx_line4 {
      color: #9597AB;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.41px;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      text-align: center;
      margin-top: 16px;
    }
    .generate {
      @include generate_bg_color();
      padding: 12px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      margin-top: 24px;

      .generate_loading_img {
        width: 32px;
        margin-right: 12px;
      }

      .generate_loading_img_rotate {
        animation: rotate 1s linear infinite;
      }

      .generate_right {

        .generate_right_top {
          @include earn_font_color();
          font-family: HarmonyOS Sans SC;
          font-size: 14px;
          font-weight: 600;
          line-height: 19.6px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }
        .generate_right_bot {
          color: #9597AB;
          font-family: HarmonyOS Sans SC;
          font-size: 12px;
          font-weight: 400;
          line-height: 14.06px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          margin-top: 4px;
        }
      }
    }
.generate2 {
  margin-top: 8px;
}

.generate3 {

  .generate_loading_img {
    width: 24px;
  }
  .generate_loading_img_rotate {
    animation: rotate 1s linear infinite;
  }
}
    .confirm_tx_line2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      
      .rememberme {
        display: flex;
        align-items: center;
        font-family: HarmonyOS Sans SC;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.41px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #9597AB;

        img {
          width: 16px;
          margin-left: 4px;
        }
      }


      .btn_switch {
        margin-left: 8px;

        .ant-switch-handle {
          width: 12px;
          height: 12px;
          top: 0px;
          left: 0;
        }
      }

      .ant-switch-checked .ant-switch-handle {
        left: unset;
        right: 0;
      }

      .ant-switch {
        min-width: 24px;
        width: 24px;
        height: 12px;
        @include switch_bg();
      }

      .ant-switch-checked {
        background-color: #673CD8 !important;
      }
    }


    .confirm_tx_line5 {
      margin-top: 12px;
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.41px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #9597AB;
      
      .a {
        color: #9597AB;
        border-bottom: 1px dashed #9597AB;
      }
    }

    .forgot_password {
      cursor: pointer;
    }

    .cancel_confirm_tx_secondary_inputbox {
      margin-top: 8px;
      border-radius: 12px;
      height: 48px;
      position: relative;

      .input {
        width: 100%;
        height: 100%;
        border-radius: 12px;
        padding: 0 80px 0 16px;
        @include earn_font_color();
        @include search_coin_input_bg();
        font-family: HarmonyOS Sans SC;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.41px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }

      .input::placeholder {
        @include transaction_data_color();
        font-family: HarmonyOS Sans SC;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.41px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
      .input:focus {
        outline: 1px solid #9597AB;
        border: none !important;
      }

      .clear {
        width: 20px;
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 44px;
      }

      .eyes {
        width: 20px;
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;
      }
    }

    .confirm_tx_btn {
      margin-top: 8px;
      border-radius: var(--Radius-Full, 100px);
      background-color: #673CD8;
      width: 100%;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 12px;
      color: var(--Text-static-white, #FFF);
      text-align: center;
      font-family: "HarmonyOS Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
    }

    .confirm_tx_btn2 {
      margin-top: 24px;
    }
  }
}

@media (max-width: 960px) {

  .secondary_metamask_icon {
    width: 20px;
    margin-right: 8px;
  }
  .cancel_confirm_tx_secondary {
    padding: 12px 0px 0;
    
    .confirm_tx_line {
      // @include earn_font_color();
      color: #9597AB;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.41px;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      text-align: left;
    }

    .confirm_tx_line3 {
      color: #9597AB;
      display: flex;
      align-items: center;
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.41px;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      text-align: left;
      margin-top: 20px;

      .yes {
        color: #59B677;
      }
    }

    .confirm_tx_line4 {
      color: #9597AB;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.41px;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      text-align: center;
      margin-top: 16px;
    }
    .generate {
      @include generate_bg_color();
      padding: 12px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      margin-top: 24px;

      .generate_loading_img {
        width: 32px;
        margin-right: 12px;
      }

      .generate_loading_img_rotate {
        animation: rotate 1s linear infinite;
      }

      .generate_right {

        .generate_right_top {
          @include earn_font_color();
          font-family: HarmonyOS Sans SC;
          font-size: 14px;
          font-weight: 600;
          line-height: 19.6px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }
        .generate_right_bot {
          color: #9597AB;
          font-family: HarmonyOS Sans SC;
          font-size: 12px;
          font-weight: 400;
          line-height: 14.06px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          margin-top: 4px;
        }
      }
    }
.generate2 {
  margin-top: 8px;
}

.generate3 {

  .generate_loading_img {
    width: 24px;
  }
  .generate_loading_img_rotate {
    animation: rotate 1s linear infinite;
  }
}
    .confirm_tx_line2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      
      .rememberme {
        display: flex;
        align-items: center;
        font-family: HarmonyOS Sans SC;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.41px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #9597AB;

        img {
          width: 16px;
          margin-left: 4px;
        }
      }


      .btn_switch {
        margin-left: 8px;

        .ant-switch-handle {
          width: 12px;
          height: 12px;
          top: 0px;
          left: 0;
        }
      }

      .ant-switch-checked .ant-switch-handle {
        left: unset;
        right: 0;
      }

      .ant-switch {
        min-width: 24px;
        width: 24px;
        height: 12px;
        @include switch_bg();
      }

      .ant-switch-checked {
        background-color: #673CD8 !important;
      }
    }


    .confirm_tx_line5 {
      margin-top: 12px;
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.41px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #9597AB;
      
      .a {
        color: #9597AB;
        border-bottom: 1px dashed #9597AB;
      }
    }

    .forgot_password {
      cursor: pointer;
    }

    .cancel_confirm_tx_secondary_inputbox {
      margin-top: 8px;
      border-radius: 12px;
      height: 48px;
      position: relative;

      .input {
        width: 100%;
        height: 100%;
        border-radius: 12px;
        padding: 0 80px 0 16px;
        @include earn_font_color();
        @include search_coin_input_bg();
        font-family: HarmonyOS Sans SC;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.41px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }

      .input::placeholder {
        @include transaction_data_color();
        font-family: HarmonyOS Sans SC;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.41px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
      .input:focus {
        outline: 1px solid #9597AB;
        border: none !important;
      }

      .clear {
        width: 20px;
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 44px;
      }

      .eyes {
        width: 20px;
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;
      }
    }

    .confirm_tx_btn {
      margin-top: 8px;
      border-radius: var(--Radius-Full, 100px);
      background-color: #673CD8;
      width: 100%;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 12px;
      color: var(--Text-static-white, #FFF);
      text-align: center;
      font-family: "HarmonyOS Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
    }

    .confirm_tx_btn2 {
      margin-top: 24px;
    }
  }
}