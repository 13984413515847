@import "../../styles/mixin.scss";
@import "../../styles/animate.scss";
@import "../../styles/fade-animate.scss";

.toast_box {
    min-width: 320px;
    padding: 16px;
    border-radius: 16px;
    @include cmodal2_bg();
    position: relative;

    .close {
        position: absolute;
        width: 16px;
        top: 16px;
        right: 16px;
        cursor: pointer;
    }

    .title {
        @include font_color();
        font-family: "HarmonyOS Sans Sc";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .toast-body {
        margin-top: 8px;
        @include font_color();
        font-family: "HarmonyOS Sans Sc";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}