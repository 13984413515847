@import "../../styles/mixin.scss";
@import "../../styles/animate.scss";
@import "../../styles/fade-animate.scss";


@media (min-width: 961px) {
  .cancel_confirm_tx_viewmne {
    padding: 12px 0px 0;
    
    .confirm_tx_line {
      // @include earn_font_color();
      color: #9597AB;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.41px;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      text-align: left;
    }

    .confirm_tx_line3 {
      color: #9597AB;
      display: flex;
      align-items: center;
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.41px;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      text-align: left;
      margin-top: 20px;

      .yes {
        color: #59B677;
      }

      .confirm_tx_line3_nd {
        width: 16px;
        height: 16px;
        border-radius: 4px;
        border: 1px solid var(--text-icon-02, #9597AB);
        margin-right: 8px;
        cursor: pointer;
      }

      .confirm_tx_line3_nd_act {
        display: flex;
        align-items: center;
        justify-content: center;
        border-color: #673CD8;

        img {
          width: 12px;
        }
      }
    }
    .generate {
      @include bg_color3();
      padding: 12px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      margin-top: 24px;

      .generate_loading_img {
        width: 32px;
        margin-right: 12px;
      }

      .generate_right {
        @include earn_font_color();
        font-family: HarmonyOS Sans SC;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.6px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
    }
    .confirm_tx_btn {
      margin-top: 12px;
      border-radius: var(--Radius-Full, 100px);
      background-color: #673CD8;
      width: 100%;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 12px;
      color: var(--Text-static-white, #FFF);
      text-align: center;
      font-family: "HarmonyOS Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
    }
    
    .confirm_tx_btn_dis {
      background-color: #9597A81A;
      color: #9597AB;
    }
  }
}

@media (max-width: 960px) {
  .cancel_confirm_tx_viewmne {
    padding: 12px 0px 0;
    
    .confirm_tx_line {
      // @include earn_font_color();
      color: #9597AB;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.41px;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      text-align: left;
    }

    .confirm_tx_line3 {
      color: #9597AB;
      display: flex;
      align-items: center;
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.41px;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      text-align: left;
      margin-top: 20px;

      .yes {
        color: #59B677;
      }

      .confirm_tx_line3_nd {
        width: 16px;
        height: 16px;
        border-radius: 4px;
        border: 1px solid var(--text-icon-02, #9597AB);
        margin-right: 8px;
        cursor: pointer;
      }

      .confirm_tx_line3_nd_act {
        display: flex;
        align-items: center;
        justify-content: center;
        border-color: #673CD8;

        img {
          width: 12px;
        }
      }
    }
    .generate {
      @include bg_color3();
      padding: 12px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      margin-top: 24px;

      .generate_loading_img {
        width: 32px;
        margin-right: 12px;
      }

      .generate_right {
        @include earn_font_color();
        font-family: HarmonyOS Sans SC;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.6px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
    }
    .confirm_tx_btn {
      margin-top: 12px;
      border-radius: var(--Radius-Full, 100px);
      background-color: #673CD8;
      width: 100%;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 12px;
      color: var(--Text-static-white, #FFF);
      text-align: center;
      font-family: "HarmonyOS Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
    }
    
    .confirm_tx_btn_dis {
      background-color: #9597A81A;
      color: #9597AB;
    }
  }
}